export const CURRENT_TOOLBAR_VIEW = {
  SHOW_DEFAULT_DISPLAY: 'SHOW_DEFAULT_DISPLAY',
  SHOW_GENERATE_DISPLAY: 'SHOW_GENERATE_DISPLAY',
  SHOW_TEMPLATES_DISPLAY: 'SHOW_TEMPLATES_DISPLAY',
  SHOW_EDIT_MASK_DISPLAY: 'SHOW_EDIT_MASK_DISPLAY',
  SHOW_EDIT_DISPLAY: 'SHOW_EDIT_DISPLAY',
  
  SHOW_ADD_TEXT_DISPLAY: 'SHOW_ADD_TEXT_DISPLAY',
  SHOW_LAYERS_DISPLAY: 'SHOW_LAYERS_DISPLAY',

  SHOW_CURSOR_SELECT_DISPLAY: 'SHOW_CURSOR_SELECT_DISPLAY',
  SHOW_ANIMATE_DISPLAY: 'SHOW_ANIMATE_DISPLAY',
  SHOW_OBJECT_SELECT_DISPLAY: 'SHOW_OBJECT_SELECT_DISPLAY',
  SHOW_ACTIONS_DISPLAY: 'SHOW_ACTIONS_DISPLAY',
  SHOW_SELECT_DISPLAY: 'SHOW_SELECT_DISPLAY',

  SHOW_ADD_SHAPE_DISPLAY: 'SHOW_ADD_SHAPE_DISPLAY',
  SHOW_UPLOAD_DISPLAY: 'SHOW_UPLOAD_DISPLAY',
  SHOW_AUDIO_DISPLAY: 'SHOW_AUDIO_DISPLAY',

  SHOW_SET_DEFAULTS_DISPLAY: 'SHOW_SET_DEFAULTS_DISPLAY',

  SHOW_GENERATE_VIDEO_DISPLAY: 'SHOW_GENERATE_VIDEO_DISPLAY',

}

export const TOOLBAR_ACTION_VIEW = {
  SHOW_DEFAULT_DISPLAY: 'SHOW_DEFAULT_DISPLAY',


  SHOW_ERASER_DISPLAY: 'SHOW_ERASER_DISPLAY',
  SHOW_PENCIL_DISPLAY: 'SHOW_PENCIL_DISPLAY',

  SHOW_SELECT_LAYER_DISPLAY: 'SHOW_SELECT_LAYER_DISPLAY',
  SHOW_SELECT_SHAPE_DISPLAY: 'SHOW_SELECT_SHAPE_DISPLAY',

  SHOW_SELECT_OBJECT_DISPLAY: 'SHOW_SELECT_OBJECT_DISPLAY',

  SHOW_MUSIC_GENERATE_DISPLAY: 'SHOW_MUSIC_GENERATE_DISPLAY',
  SHOW_SPEECH_GENERATE_DISPLAY: 'SHOW_SPEECH_GENERATE_DISPLAY',
  SHOW_SOUND_GENERATE_DISPLAY: 'SHOW_SOUND_GENERATE_DISPLAY',

  SHOW_PREVIEW_MUSIC_DISPLAY: 'SHOW_PREVIEW_MUSIC_DISPLAY',
  SHOW_PREVIEW_SPEECH_DISPLAY: 'SHOW_PREVIEW_SPEECH_DISPLAY',
  SHOW_PREVIEW_SPEECH_LAYERED_DISPLAY: 'SHOW_PREVIEW_SPEECH_LAYERED_DISPLAY',
  SHOW_PREVIEW_SOUND_DISPLAY: 'SHOW_PREVIEW_SOUND_DISPLAY',

  SHOW_LIBRARY_DISPLAY: 'SHOW_LIBRARY_DISPLAY',
  SHOW_SMART_SELECT_DISPLAY: 'SHOW_SMART_SELECT_DISPLAY',

  SHOW_SUBTITLES_DISPLAY: 'SHOW_SUBTITLES_DISPLAY',
}

export const FRAME_TOOLBAR_VIEW = {
  DEFAULT: 'DEFAULT',
  AUDIO: 'AUDIO',
  EXPANDED: 'EXPANDED'
}

export const CURRENT_EDITOR_VIEW = {
  'VIEW': 'VIEW',
  'EDIT': 'EDIT',
}

export const IMAGE_GENERAITON_MODEL_TYPES = [

  {
    name: 'Dall-E 3',
    key: 'DALLE3'
  },

  {
    name: 'Flux-1 Pro',
    key: 'FLUX1PRO'
  },
  {
    name: 'Flux-1.1 Pro',
    key: 'FLUX1.1PRO'
  },
  {
    name: 'Flux 1.1 Ultra',
    key: 'FLUX1.1ULTRA'
  },

  {
    name: 'Flux-1 Dev',
    key: 'FLUX1DEV'
  },

  {
    name: 'Recraft V3',
    key: 'RECRAFTV3'
  },

  {
    name: 'Stable Diffusion V3.5',
    key: 'SDV3.5'
  },

  {
    name: 'Nvidia Sana',
    key: 'SANA'
  },
  {
    name: 'Recraft 20B',
    key: 'RECRAFT20B'
  },
  {
    name: 'Lumalabs Photon',
    key: 'PHOTON'
  },
  {
    name: 'Lumalabs Photon Flash',
    key: 'PHOTONFLASH'
  }



]

export const VIDEO_GENERATION_MODEL_TYPES = [
  {
    name: 'Luma AI',
    key: 'LUMA',
  },
  {
    name: 'Runway Gen-3',
    key: 'RUNWAYML',
    isExpressModel: true,
  },

  {
    name: 'SD Video',
    key: 'SDVIDEO'
  },
  {
    name: 'Hailuo Minimax',
    key: 'HAILUO'
  },
  {
    name: 'Haiper 2.0',
    key: 'HAIPER2.0'
  },
  {
    name: 'Kling 1.5 Pro',
    key: 'KLINGIMGTOVIDPRO'
  }

]


export const IMAGE_EDIT_MODEL_TYPES = [

  {
    name: 'Flux-1 Pro Fill',
    key: 'FLUX1PROFILL',
    editType: 'inpaint'
  },


  {
    name: 'Flux-1.1 Pro Ultra Redux',
    key: 'FLUX1.1PROULTRAREDUX',
    editType: 'prompt'
  },


  {
    name: 'Flux-1.1 Pro Redux',
    key: 'FLUX1.1PROREDUX',
    editType: 'prompt'
  }
]


export const CANVAS_ACTION = {
  MOVE: 'MOVE',
  EDIT: 'EDIT',
  RESIZE: 'RESIZE',
  DEFAULT: 'DEFAULT',
}


export const SPEAKER_TYPES = [
  'alloy',
  'echo',
  'fable',
  'onyx',
  'nova',
  'shimmer'
];



export const SPEECH_SELECT_TYPES = {
  SPEECH_LAYER: 'SPEECH_LAYER',
  SPEECH_PER_SCENE: 'SPEECH_PER_SCENE',
};


export const ASSISTANT_MODEL_TYPES = [
  'GPT4O',
  'GEMINI1.5PRO'
];



export const RECRAFT_IMAGE_STYLES = [
  "realistic_image",
  "digital_illustration",
  "vector_illustration",
  "realistic_image/b_and_w",
  "realistic_image/hard_flash",
  "realistic_image/hdr",
  "realistic_image/natural_light",
  "realistic_image/studio_portrait",
  "realistic_image/enterprise",
  "realistic_image/motion_blur",
  "digital_illustration/pixel_art",
  "digital_illustration/hand_drawn",
  "digital_illustration/grain",
  "digital_illustration/infantile_sketch",
  "digital_illustration/2d_art_poster",
  "digital_illustration/handmade_3d",
  "digital_illustration/hand_drawn_outline",
  "digital_illustration/engraving_color",
  "digital_illustration/2d_art_poster_2",
  "vector_illustration/engraving",
  "vector_illustration/line_art",
  "vector_illustration/line_circuit",
  "vector_illustration/linocut"
];



export const TTS_PROVIDERS = [
  { value: 'OPENAI', label: 'OpenAI' },
  { value: 'PLAYHT', label: 'Play.ht' },
];

export const OPENAI_SPEAKER_TYPES = [
  { value: 'alloy', label: 'Alloy', provider: 'OPENAI' ,
    "Gender": "F",
  },
  { value: 'echo', label: 'Echo', provider: 'OPENAI',
    "Gender": "M",
   },
  { value: 'fable', label: 'Fable', provider: 'OPENAI',
    "Gender": "F",
   },
  { value: 'onyx', label: 'Onyx', provider: 'OPENAI',
    "Gender": "M",
   },
  { value: 'nova', label: 'Nova', provider: 'OPENAI' ,
    "Gender": "F",
  },
  { value: 'shimmer', label: 'Shimmer', provider: 'OPENAI',
    "Gender": "F",
   },
];



export const MUSIC_PROVIDERS = [
  {
    name: 'AudioCraft',
    key: 'AUDIOCRAFT'
  }
]


export const PLAYHT_SPEAKER_TYPES =
  [
    {
      "Voice": "Angelo",
      "value": "Angelo",
      "label": "Angelo",
      "Accent": "US",
      "Gender": "M",
      "Age": "Young",
      "Style": "Conversational",
      "Voice Name": "Dialog Angelo",
      "ID": "s3://voice-cloning-zero-shot/baf1ef41-36b6-428c-9bdf-50ba54682bd8/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Arsenio",
      "value": "Arsenio",
      "label": "Arsenio",
      "Accent": "US African American",
      "Gender": "M",
      "Age": "Middle",
      "Style": "Conversational",
      "Voice Name": "Dialog Arsenio",
      "ID": "s3://voice-cloning-zero-shot/65977f5e-a22a-4b36-861b-ecede19bdd65/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Cillian",
      "value": "Cillian",
      "label": "Cillian",
      "Accent": "Irish",
      "Gender": "M",
      "Age": "Middle",
      "Style": "Conversational",
      "Voice Name": "Dialog Cillian",
      "ID": "s3://voice-cloning-zero-shot/1591b954-8760-41a9-bc58-9176a68c5726/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Timo",
      "value": "Timo",
      "label": "Timo",
      "Accent": "US",
      "Gender": "M",
      "Age": "Middle",
      "Style": "Conversational",
      "Voice Name": "Dialog Timo",
      "ID": "s3://voice-cloning-zero-shot/677a4ae3-252f-476e-85ce-eeed68e85951/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Dexter",
      "value": "Dexter",
      "label": "Dexter",
      "Accent": "US",
      "Gender": "M",
      "Age": "Middle",
      "Style": "Conversational",
      "Voice Name": "Dialog Dexter",
      "ID": "s3://voice-cloning-zero-shot/b27bc13e-996f-4841-b584-4d35801aea98/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Miles",
      "value": "Miles",
      "label": "Miles",
      "Accent": "US African American",
      "Gender": "M",
      "Age": "Young",
      "Style": "Conversational",
      "Voice Name": "Dialog Miles",
      "ID": "s3://voice-cloning-zero-shot/29dd9a52-bd32-4a6e-bff1-bbb98dcc286a/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Briggs",
      "value": "Briggs",
      "label": "Briggs",
      "Accent": "US Southern (Oklahoma)",
      "Gender": "M",
      "Age": "Old",
      "Style": "Conversational",
      "Voice Name": "Dialog Briggs",
      "ID": "s3://voice-cloning-zero-shot/71cdb799-1e03-41c6-8a05-f7cd55134b0b/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Deedee",
      "value": "Deedee",
      "label": "Deedee",
      "Accent": "US African American",
      "Gender": "F",
      "Age": "Middle",
      "Style": "Conversational",
      "Voice Name": "Dialog Deedee",
      "ID": "s3://voice-cloning-zero-shot/e040bd1b-f190-4bdb-83f0-75ef85b18f84/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Nia",
      "value": "Nia",
      "label": "Nia",
      "Accent": "US",
      "Gender": "F",
      "Age": "Young",
      "Style": "Conversational",
      "Voice Name": "Dialog Nia",
      "ID": "s3://voice-cloning-zero-shot/831bd330-85c6-4333-b2b4-10c476ea3491/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Inara",
      "value": "Inara",
      "label": "Inara",
      "Accent": "US African American",
      "Gender": "F",
      "Age": "Middle",
      "Style": "Conversational",
      "Voice Name": "Dialog Inara",
      "ID": "s3://voice-cloning-zero-shot/adb83b67-8d75-48ff-ad4d-a0840d231ef1/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Constanza",
      "value": "Constanza",
      "label": "Constanza",
      "Accent": "US Latin American",
      "Gender": "F",
      "Age": "Young",
      "Style": "Conversational",
      "Voice Name": "Dialog Constanza",
      "ID": "s3://voice-cloning-zero-shot/b0aca4d7-1738-4848-a80b-307ac44a7298/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Gideon",
      "value": "Gideon",
      "label": "Gideon",
      "Accent": "British",
      "Gender": "M",
      "Age": "Old",
      "Style": "Narrative",
      "Voice Name": "Dialog Gideon",
      "ID": "s3://voice-cloning-zero-shot/5a3a1168-7793-4b2c-8f90-aff2b5232131/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Casper",
      "value": "Casper",
      "label": "Casper",
      "Accent": "US",
      "Gender": "M",
      "Age": "Middle",
      "Style": "Narrative",
      "Voice Name": "Dialog Casper",
      "ID": "s3://voice-cloning-zero-shot/1bbc6986-fadf-4bd8-98aa-b86fed0476e9/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Mitch",
      "value": "Mitch",
      "label": "Mitch",
      "Accent": "Australian",
      "Gender": "M",
      "Age": "Middle",
      "Style": "Narrative",
      "Voice Name": "Dialog Mitch",
      "ID": "s3://voice-cloning-zero-shot/c14e50f2-c5e3-47d1-8c45-fa4b67803d19/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Ava",
      "value": "Ava",
      "label": "Ava",
      "Accent": "Australian",
      "Gender": "F",
      "Age": "Middle",
      "Style": "Narrative",
      "Voice Name": "Dialog Ava",
      "ID": "s3://voice-cloning-zero-shot/50381567-ff7b-46d2-bfdc-a9584a85e08d/original/manifest.json",
      "provider": "PLAYHT"
    },
    {
      "Voice": "Basil",
      "value": "Basil",
      "label": "Basil",
      "Accent": "British (Yorkshire)",
      "Gender": "M",
      "Age": "Middle",
      "Style": "Narrative",
      "Voice Name": "Dialog Basil",
      "ID": "s3://voice-cloning-zero-shot/baf1ef41-36b6-428c-9bdf-50ba54682bd8/original/manifest.json",
      "provider": "PLAYHT"
    }
  ];



  // constants/Types.ts
export const TTS_COMBINED_SPEAKER_TYPES = [
  ...OPENAI_SPEAKER_TYPES,
  ...PLAYHT_SPEAKER_TYPES
];

