import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Konva from 'konva';
import { useUser } from '../../contexts/UserContext.js';
import { useAlertDialog } from '../../contexts/AlertDialogContext.js';
import { useColorMode } from '../../contexts/ColorMode.js';
import { getHeaders } from '../../utils/web.js';
import { CURRENT_TOOLBAR_VIEW, CANVAS_ACTION, TOOLBAR_ACTION_VIEW, IMAGE_GENERAITON_MODEL_TYPES, IMAGE_EDIT_MODEL_TYPES } from '../../constants/Types.ts';
import { STAGE_DIMENSIONS } from '../../constants/Image.js';
import SelectTemplate from '../editor/SelectTemplate.tsx';
import UploadImageDialog from '../editor/utils/UploadImageDialog.js';
import VideoCanvasContainer from './editor/VideoCanvasContainer.js';
import VideoEditorToolbar from './toolbars/VideoEditorToolbar.js'
import LoadingImage from './util/LoadingImage.js';
import LoadingImageTransparent from './util/LoadingImageTransparent.js';
import ImageLibrary from './util/ImageLibrary.js';

import { getTextConfigForCanvas } from '../../constants/TextConfig.js';


import LibraryHome from '../library/LibraryHome.js';


import AuthContainer from '../auth/AuthContainer.js';

import VideoEditorToolbarMinimal from './toolbars/VideoEditorToolbarMinimal.js';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { getCanvasDimensionsForAspectRatio } from '../../utils/canvas.js';



const PROCESSOR_API_URL = process.env.REACT_APP_PROCESSOR_API;
const STATIC_CDN_URL = process.env.REACT_APP_STATIC_CDN_URL;


export default function VideoEditorContainer(props) {
  const { selectedLayerId, currentLayerSeek,
    currentLayer, updateSessionLayerActiveItemList,
    updateSessionLayerActiveItemListAnimations,
    activeItemList, setActiveItemList, isLayerSeeking,
    showAddAudioToProjectDialog, generationImages,
    updateCurrentActiveLayer,
    videoSessionDetails,
    setVideoSessionDetails,
    toggleHideItemInLayer,
    pollForLayersUpdate,
    setIsCanvasDirty,
    updateCurrentLayer,
    applyAnimationToAllLayers,
    setGenerationImages,
    isExpressGeneration,
    aspectRatio,
    displayZoomType,
    toggleStageZoom,
    stageZoomScale,
    updateCurrentLayerInSessionList,
    updateCurrentLayerAndLayerList,
    totalDuration,
  } = props;

  const [segmentationData, setSegmentationData] = useState([]);

  const [currentLayerDefaultPrompt, setCurrentLayerDefaultPrompt] = useState('');


  const [aiVideoLayer, setAiVideoLayer] = useState(null);


  let { id } = useParams();

  const showLoginDialog = () => {
    const loginComponent = (
      <AuthContainer />
    )
    openAlertDialog(loginComponent);
  };

  const generationPollIntervalRef = useRef(null);
  const outpaintPollIntervalRef = useRef(null);
  const audioGenerationPollIntervalRef = useRef(null);
  const maskGenerationPollIntervalRef = useRef(null);
  const aiVideoGenerationPollIntervalRef = useRef(null);


  const getAIVIdeoLink = () => {
    if (currentLayer && currentLayer.aiVideoLayer) {
      let aiVideoUrl;
      if (currentLayer.aiVideoRemoteLink) {
        aiVideoUrl = `${STATIC_CDN_URL}/${currentLayer.aiVideoRemoteLink}`;
      } else {
        aiVideoUrl = `${PROCESSOR_API_URL}${currentLayer.aiVideoLayer}`;
      }
      return aiVideoUrl;
    }
  }
  useEffect(() => {
    if (currentLayer && currentLayer.aiVideoLayer) {
      let aiVideoUrl = getAIVIdeoLink();

      setAiVideoLayer(aiVideoUrl);
    } else {
      setAiVideoLayer(null);
    }
  }, [currentLayer]);




  useEffect(() => {
    if (currentLayer && currentLayer.aiVideoLayer) {
      const hiddenContainer = document.getElementById('hidden-video-container');
      const videoSrc = getAIVIdeoLink();
      const video = document.createElement('video');
      video.src = videoSrc;
      video.preload = 'auto';
      video.style.display = 'none'; // Hide the video
      hiddenContainer.appendChild(video);
    }
  }, [aiVideoLayer]); // Ensure this runs when aiVideoLayer updates



  useEffect(() => {
    if (currentLayer && currentLayer.segmentation) {
      setSegmentationData(currentLayer.segmentation);
    }

    if (currentLayer && currentLayer.imageSession && currentLayer.imageSession.generationStatus === 'PENDING') {
      pollForLayersUpdate();
    }

    let currentDefaultPrompt = '';
    if (currentLayer && currentLayer.prompt) {
      currentDefaultPrompt = currentLayer.prompt;
    }
    setCurrentLayerDefaultPrompt(currentDefaultPrompt);


    return () => {
      if (generationPollIntervalRef.current) {
        clearInterval(generationPollIntervalRef.current);
      }
      if (outpaintPollIntervalRef.current) {
        clearInterval(outpaintPollIntervalRef.current);
      }
      if (maskGenerationPollIntervalRef.current) {
        clearInterval(maskGenerationPollIntervalRef.current);
      }
      if (aiVideoGenerationPollIntervalRef.current) {
        clearInterval(aiVideoGenerationPollIntervalRef.current);
      }
    };
  }, [currentLayer]);

  if (!id) {
    id = props.id;
  }

  const [promptText, setPromptText] = useState("");
  const [videoPromptText, setVideoPromptText] = useState("");
  const [selectedVideoGenerationModel, setSelectedVideoGenerationModel] = useState('LUMA');

  const [selectedChain, setSelectedChain] = useState('');
  const [selectedAllocation, setSelectedAllocation] = useState(300);
  const [isTemplateSelectViewSelected, setIsTemplateSelectViewSelected] = useState(false);
  const [templateOptionList, setTemplateOptionList] = useState([]);

  const [editBrushWidth, setEditBrushWidth] = useState(25);
  const [editMasklines, setEditMaskLines] = useState([]);

  const [currentView, setCurrentView] = useState(CURRENT_TOOLBAR_VIEW.SHOW_DEFAULT_DISPLAY);
  const [currentCanvasAction, setCurrentCanvasAction] = useState(TOOLBAR_ACTION_VIEW.SHOW_DEFAULT_DISPLAY);

  const [selectedGenerationModel, setSelectedGenerationModel] = useState(() => {
    const defaultModel = localStorage.getItem('defaultModel');
    if (defaultModel && defaultModel !== undefined) {
      return defaultModel;
    } else {
      return IMAGE_GENERAITON_MODEL_TYPES[0].key;
    }
  });

  const [selectedEditModel, setSelectedEditModel] = useState(IMAGE_EDIT_MODEL_TYPES[0].key);




  const [isGenerationPending, setIsGenerationPending] = useState(false);
  const [isOutpaintPending, setIsOutpaintPending] = useState(false);
  const [isPublicationPending, setIsPublicationPending] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const { colorMode } = useColorMode();
  const initialBackgroundFillColor = colorMode === 'dark' ? '#030712' : '#f5f5f5';
  const initFillColor = colorMode === 'dark' ? '#f5f5f5' : '#030712';
  const initTextFillColor = colorMode === 'dark' ? '#000000' : '#ffffff';

  const [fillColor, setFillColor] = useState(initFillColor);
  const [strokeColor, setStrokeColor] = useState(initFillColor);
  const [strokeWidthValue, setStrokeWidthValue] = useState(2);
  const [buttonPositions, setButtonPositions] = useState([]);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedLayerType, setSelectedLayerType] = useState(null);

  const [pencilWidth, setPencilWidth] = useState(10);
  const [pencilColor, setPencilColor] = useState('#000000');
  const [eraserWidth, setEraserWidth] = useState(30);
  const [eraserOptionsVisible, setEraserOptionsVisible] = useState(false);
  const [cursorSelectOptionVisible, setCursorSelectOptionVisible] = useState(false);

  const [generationError, setGenerationError] = useState(null);
  const [outpaintError, setOutpaintError] = useState(null);

  const [selectedLayerSelectShape, setSelectedLayerSelectShape] = useState(null);

  const [audioGenerationPending, setAudioGenerationPending] = useState(false);

  const [enableSegmentationMask, setEnableSegmentationMask] = useState(false);

  const [canvasActionLoading, setCanvasActionLoading] = useState(false);

  const [isAIVideoGenerationPending, setIsAIVideoGenerationPending] = useState(false);





  const [textConfig, setTextConfig] = useState(null);


  useEffect(() => {
    if (aspectRatio) {
      const canvasDimensions = getCanvasDimensionsForAspectRatio(aspectRatio);

      const defaultDimensions = getTextConfigForCanvas(textConfig, canvasDimensions);
      
      setTextConfig(defaultDimensions);


    }
  }, [aspectRatio]);

  const [showCreateNewPromptDisplay, setShowCreateNewPromptDisplay] = useState(false);
  const showCreateNewPrompt = () => {
    setShowCreateNewPromptDisplay(true);

  }


  const setCurrentViewDisplay = (view) => {
    setCurrentView(view);
  }



  const { openAlertDialog, closeAlertDialog, setIsAlertActionPending } = useAlertDialog();
  const { user, getUserAPI } = useUser();

  const [showMask, setShowMask] = useState(false);

  const canvasRef = useRef(null);
  const maskGroupRef = useRef(null);


  useEffect(() => {
    if (aspectRatio) {
      const canvasDimensions = getCanvasDimensionsForAspectRatio(aspectRatio);
      const canvasMidX = canvasDimensions.width / 2;
      const canvasMidY = canvasDimensions.height / 2;
      setTextConfig({
        ...textConfig,
        x: canvasMidX,
        y: canvasMidY,
      });
    }
  }, [aspectRatio]);

  useEffect(() => {
    setIsAlertActionPending(isPublicationPending);
  }, [isPublicationPending]);



  const setUploadURL = useCallback((data) => {
    if (!data) {
      return;
    }
    const newItemId = `item_${activeItemList.length}`;

    let newItem = {
      src: data.url,
      id: newItemId,
      type: 'image',
      x: data.x,
      y: data.y,
      width: data.width,
      height: data.height
    };

    const newItemList = [...activeItemList, newItem];
    setActiveItemList(newItemList);
    updateSessionLayerActiveItemList(newItemList);
    closeAlertDialog();
    toast.success(<div><FaCheck className='inline-flex mr-2' /> Image uploaded successfully!</div>, {
      position: "bottom-center",
      className: "custom-toast",
    });
  }, [activeItemList]);

  useEffect(() => {
    if (currentCanvasAction === TOOLBAR_ACTION_VIEW.SHOW_ERASER_DISPLAY) {
      setSelectedId(null);
    }
  }, [currentCanvasAction]);

  const resetCurrentView = () => {
    setCurrentView(CURRENT_TOOLBAR_VIEW.SHOW_DEFAULT_DISPLAY);
  }

  const prevLengthRef = useRef(activeItemList.length);
  const [isIntermediateSaving, setIsIntermediateSaving] = useState(false);

  useEffect(() => {
    const currentLength = activeItemList.length;

    if (prevLengthRef.current !== currentLength) {
      if (!isIntermediateSaving) {
        setIsIntermediateSaving(true);
      }
    }

    prevLengthRef.current = currentLength;
  }, [activeItemList.length]);


  const submitGenerateRequest = async (payload) => {



    setIsGenerationPending(true);

    setGenerationError(null);

    payload.aspectRatio = aspectRatio;

    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }
    axios.post(`${PROCESSOR_API_URL}/video_sessions/request_generate`, payload, headers)
      .then((resData) => {
        const response = resData.data;
        const updatedPrompt = response.prompt;
        if (payload.isRecreateRequest) {
          setCurrentLayerDefaultPrompt(updatedPrompt);
        }
        startGenerationPoll();
        toast.success(<div><FaCheck className='inline-flex mr-2' /> Generation request submitted successfully!</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      })
      .catch(error => {
        setIsGenerationPending(false);
        setGenerationError(error.message);
        toast.error(<div><FaTimes /> Failed to submit generation request.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      });
  }


  const submitGenerateNewRequest = async (inputPayload) => {


    const payload = {
      ...inputPayload,
      prompt: promptText,
      videoSessionId: id,
      model: selectedGenerationModel,
      layerId: currentLayer._id.toString()
    };

    await submitGenerateRequest(payload);

  }

  const submitGenerateRecreateRequest = async (payload) => {
    payload = {
      ...payload,
      videoSessionId: id,
      model: payload.model ? payload.model : selectedGenerationModel,
      layerId: currentLayer._id.toString(),
      skipApplyThemeToPrompt: true,
      isRecreateRequest: true,
    };

    await submitGenerateRequest(payload);
  }



  const [selectedEditModelValue, setSelectedEditModelValue] = useState(IMAGE_EDIT_MODEL_TYPES.find((model) => model.key === selectedEditModel));



  useEffect(() => {
    setSelectedEditModelValue(IMAGE_EDIT_MODEL_TYPES.find((model) => model.key === selectedEditModel));

  }, [selectedEditModel]);

  useEffect(() => {

    if (currentView !== CURRENT_TOOLBAR_VIEW.SHOW_EDIT_DISPLAY ||
      (selectedEditModelValue && selectedEditModelValue.editType !== 'inpaint')) {

      setEditMaskLines([]);
    }
  }, [currentView, selectedEditModelValue]);



  const submitOutpaintRequest = async (evt) => {
    evt.preventDefault();
    setIsOutpaintPending(true);


    let isMaskRequired = false;
    let maskImageData;
    const baseImageData = await exportBaseGroup();
    if (selectedEditModelValue && selectedEditModelValue.editType === 'inpaint') {
      isMaskRequired = true;




      maskImageData = await exportMaskedGroupAsBlackAndWhite();


    }

    // Continue with the outpainting request processing
    const formData = new FormData(evt.target);
    const promptText = formData.get('promptText');
    const guidanceScale = formData.get('guidanceScale');
    const numInferenceSteps = formData.get('numInferenceSteps');
    const strength = formData.get('strength');
    const aspectRatio = videoSessionDetails.aspectRatio;

    let payload = {
      image: baseImageData,
      sessionId: id,
      layerId: currentLayer._id.toString(),
      prompt: promptText,
      model: selectedEditModel,
      guidanceScale: guidanceScale,
      numInferenceSteps: numInferenceSteps,
      strength: strength,
      aspectRatio: aspectRatio
    };
    

    if (isMaskRequired) {
      payload['maskImage'] = maskImageData;
    }
    setOutpaintError(null);
    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }

    axios.post(`${PROCESSOR_API_URL}/video_sessions/request_edit_image`, payload, headers)
      .then(() => {
        startOutpaintPoll();
        toast.success(<div><FaCheck className='inline-flex mr-2' /> Edit request submitted successfully!</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      })
      .catch(error => {
        setOutpaintError(error.message);
        toast.error(<div><FaTimes /> Failed to submit edit request.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      });
  };




  async function exportBaseGroup() {
    const stage = canvasRef.current.getStage();
  
    const stageDimensions = getCanvasDimensionsForAspectRatio(aspectRatio);
  
    // Create an offscreen canvas
    const canvas = document.createElement('canvas');
    canvas.width = stageDimensions.width;
    canvas.height = stageDimensions.height;
    const ctx = canvas.getContext('2d');
  
    // Get the active item list from the current layer
    const activeItemList = currentLayer.imageSession.activeItemList;
  
    // Helper function to load an image
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        // Handle CORS if needed
        if (!src.startsWith('data:')) {
          img.crossOrigin = 'Anonymous';
        }
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
        img.src = src.startsWith('http') ? src : `${PROCESSOR_API_URL}/${src}`;
      });
    };
  
    // Loop through each item and draw it onto the canvas
    for (const item of activeItemList) {
      ctx.save(); // Save the context state
  
      // Use positions and dimensions directly, assuming they are in original units
      const x = item.x;
      const y = item.y;
      const width = item.width;
      const height = item.height;
  
      // Reset any transformations
      ctx.setTransform(1, 0, 0, 1, 0, 0);
  
      // Apply transformations
      // Translate to the item's position
      ctx.translate(x, y);
  
      // Apply rotation if any
      if (item.rotation) {
        // Rotate around the item's center
        ctx.translate(width / 2, height / 2);
        ctx.rotate((item.rotation * Math.PI) / 180);
        ctx.translate(-width / 2, -height / 2);
      }
  
      // Apply scaling if any
      const scaleX = item.scaleX || 1;
      const scaleY = item.scaleY || 1;
      //ctx.scale(scaleX, scaleY);
  
      if (item.type === 'image') {
        // Load the image
        const imgSrc = item.src.startsWith('data:') ? item.src : `${PROCESSOR_API_URL}/${item.src}`;
        try {
          const img = await loadImage(imgSrc);
          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0, width, height);
        } catch (error) {
          console.error('Error loading image:', error);
        }
      } else if (item.type === 'text') {
        // Use font size directly
        const fontSize = item.config.fontSize || 40;
  
        // Set text styles
        ctx.fillStyle = item.config.fillColor || '#000000';
        ctx.font = `${fontSize}px ${item.config.fontFamily || 'Arial'}`;
        ctx.textAlign = item.config.align || 'left';
        ctx.textBaseline = 'top';
  
        // Draw the text
        ctx.fillText(item.text, 0, 0);
      } else if (item.type === 'shape') {
        // Handle different shapes
        const config = item.config;
  
        // Use positions and dimensions directly
        const shapeX = config.x || 0;
        const shapeY = config.y || 0;
        const shapeWidth = config.width || 0;
        const shapeHeight = config.height || 0;
        const radius = config.radius || 0;
        const strokeWidth = config.strokeWidth || 1;
  
        ctx.fillStyle = config.fillColor || '#000000';
        ctx.strokeStyle = config.strokeColor || '#000000';
        ctx.lineWidth = strokeWidth;
  
        if (item.shape === 'rectangle') {
          ctx.fillRect(shapeX, shapeY, shapeWidth, shapeHeight);
          ctx.strokeRect(shapeX, shapeY, shapeWidth, shapeHeight);
        } else if (item.shape === 'circle') {
          ctx.beginPath();
          ctx.arc(
            shapeX + radius,
            shapeY + radius,
            radius,
            0,
            2 * Math.PI
          );
          ctx.fill();
          ctx.stroke();
        }
        // Add other shapes as needed
      }
  
      ctx.restore(); // Restore the context state
    }
  
    // Get the data URL of the canvas
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }




  const downloadCurrentFrame = async () => {
    const stage = canvasRef.current.getStage();

    const isPremiumUser = user.isPremiumUser;

    const waterMarkImage = 'wm.png';
    
  
    const stageDimensions = getCanvasDimensionsForAspectRatio(aspectRatio);
  
    // Create an offscreen canvas
    const canvas = document.createElement('canvas');
    canvas.width = stageDimensions.width;
    canvas.height = stageDimensions.height;
    const ctx = canvas.getContext('2d');
  
    // Get the active item list from the current layer
    const activeItemList = currentLayer.imageSession.activeItemList;
  
    const loadLocalImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';

        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
        img.src = `/${src}`;
      });
    };

    // Helper function to load an image
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        // Handle CORS if needed
        if (!src.startsWith('data:')) {
          img.crossOrigin = 'Anonymous';
        }
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
        img.src = src.startsWith('http') ? src : `${PROCESSOR_API_URL}/${src}`;
      });
    };
  
    // Loop through each item and draw it onto the canvas
    for (const item of activeItemList) {
      ctx.save(); // Save the context state
  
      // Use positions and dimensions directly, assuming they are in original units
      const x = item.x;
      const y = item.y;
      const width = item.width;
      const height = item.height;
  
      // Reset any transformations
      ctx.setTransform(1, 0, 0, 1, 0, 0);
  
      // Apply transformations
      // Translate to the item's position
      ctx.translate(x, y);
  
      // Apply rotation if any
      if (item.rotation) {
        // Rotate around the item's center
        ctx.translate(width / 2, height / 2);
        ctx.rotate((item.rotation * Math.PI) / 180);
        ctx.translate(-width / 2, -height / 2);
      }
  
      // Apply scaling if any
      const scaleX = item.scaleX || 1;
      const scaleY = item.scaleY || 1;
      //ctx.scale(scaleX, scaleY);
  
      if (item.type === 'image') {
        // Load the image
        const imgSrc = item.src.startsWith('data:') ? item.src : `${PROCESSOR_API_URL}/${item.src}`;
        try {
          const img = await loadImage(imgSrc);
          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0, width, height);
        } catch (error) {
          console.error('Error loading image:', error);
        }
      } else if (item.type === 'text') {
        // Use font size directly
        const fontSize = item.config.fontSize || 40;
  
        // Set text styles
        ctx.fillStyle = item.config.fillColor || '#000000';
        ctx.font = `${fontSize}px ${item.config.fontFamily || 'Arial'}`;
        ctx.textAlign = item.config.align || 'left';
        ctx.textBaseline = 'top';
  
        // Draw the text
        ctx.fillText(item.text, 0, 0);
      } else if (item.type === 'shape') {
        // Handle different shapes
        const config = item.config;
  
        // Use positions and dimensions directly
        const shapeX = config.x || 0;
        const shapeY = config.y || 0;
        const shapeWidth = config.width || 0;
        const shapeHeight = config.height || 0;
        const radius = config.radius || 0;
        const strokeWidth = config.strokeWidth || 1;
  
        ctx.fillStyle = config.fillColor || '#000000';
        ctx.strokeStyle = config.strokeColor || '#000000';
        ctx.lineWidth = strokeWidth;
  
        if (item.shape === 'rectangle') {
          ctx.fillRect(shapeX, shapeY, shapeWidth, shapeHeight);
          ctx.strokeRect(shapeX, shapeY, shapeWidth, shapeHeight);
        } else if (item.shape === 'circle') {
          ctx.beginPath();
          ctx.arc(
            shapeX + radius,
            shapeY + radius,
            radius,
            0,
            2 * Math.PI
          );
          ctx.fill();
          ctx.stroke();
        }
        // Add other shapes as needed
      }
  
      ctx.restore(); // Restore the context state
    }

    if (!isPremiumUser) {
      try {
        const watermarkImg = await loadLocalImage(waterMarkImage);
        const padding = 16;
        const x = canvas.width - watermarkImg.width - padding / 2;
        const y = canvas.height - watermarkImg.height - padding ;
        ctx.drawImage(watermarkImg, x, y, watermarkImg.width, watermarkImg.height);
      } catch (error) {
        console.error('Error loading watermark image:', error);
      }
    }
  
    // Get the data URL of the canvas
    const dataURL = canvas.toDataURL('image/png');
  
    // Trigger the download
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'frame.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };




  async function exportMaskedGroupAsBlackAndWhite() {
    const baseStage = canvasRef.current;
    const baseLayer = baseStage.getLayers()[0];
  
    const maskGroup = baseLayer.findOne((node) => node.id() === 'maskGroup');
  
    // Create an offscreen canvas matching the base stage dimensions
    const offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.width = baseStage.width();
    offscreenCanvas.height = baseStage.height();
    const ctx = offscreenCanvas.getContext('2d');
  
    // Hide transformers before exporting
    const transformers = baseStage.find('Transformer');
    const transformerVisibility = []; // to store original visibility
    transformers.forEach((tr) => {
      transformerVisibility.push(tr.visible());
      tr.visible(false);
    });
  
    // Get the image data from the original canvas
    const baseCanvas = await baseStage.toCanvas({ pixelRatio: 1 });
  
    // Restore transformer visibility
    transformers.forEach((tr, index) => {
      tr.visible(transformerVisibility[index]);
    });
  
    const baseCtx = baseCanvas.getContext('2d');
    const baseImageData = baseCtx.getImageData(0, 0, baseCanvas.width, baseCanvas.height);
    const imageData = baseImageData.data;
  
    // Create a new ImageData for the mask
    const maskImageData = ctx.createImageData(baseCanvas.width, baseCanvas.height);
    const maskData = maskImageData.data;
  
    // Process each pixel
    for (let i = 0; i < imageData.length; i += 4) {
      const alpha = imageData[i + 3]; // Alpha value
  
      if (alpha === 0) {
        // Transparent pixel, set to white
        maskData[i] = 255;     // Red
        maskData[i + 1] = 255; // Green
        maskData[i + 2] = 255; // Blue
        maskData[i + 3] = 255; // Alpha
      } else {
        // Opaque pixel, set to black
        maskData[i] = 0;       // Red
        maskData[i + 1] = 0;   // Green
        maskData[i + 2] = 0;   // Blue
        maskData[i + 3] = 255; // Alpha
      }
    }
  
    // Put the mask data onto the offscreen canvas
    ctx.putImageData(maskImageData, 0, 0);
  
    // Now, if maskGroup is present, draw the mask group (masked areas) as white
    if (maskGroup) {
      ctx.globalCompositeOperation = 'source-over';
      ctx.fillStyle = 'white';
  
      // Draw each line in the mask group onto the canvas
      maskGroup.children.forEach((line) => {
        ctx.beginPath();
        const points = line.points();
        ctx.moveTo(points[0], points[1]);
        for (let i = 2; i < points.length; i += 2) {
          ctx.lineTo(points[i], points[i + 1]);
        }
        ctx.closePath();
        ctx.fill();
      });
    }
  
    // Convert the offscreen canvas to a data URL
    const dataUrl = offscreenCanvas.toDataURL('image/png');
    return dataUrl;
  }


  
  async function startGenerationPoll() {
    const selectedLayerId = currentLayer._id.toString();
    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }
    const pollStatusData = await axios.get(`${PROCESSOR_API_URL}/video_sessions/generate_status?id=${id}&layerId=${selectedLayerId}`, headers);

    const pollStatus = pollStatusData.data;


    if (pollStatus.status === 'COMPLETED') {


      const layerData = pollStatus.layer;
      const layerList = pollStatus.layers;

      const updatedLayerIndex = layerList.findIndex(layer => layer._id.toString() === layerData._id.toString());
      const imageSession = layerData.imageSession;

      setCurrentLayerDefaultPrompt(layerData.prompt);
      setShowCreateNewPromptDisplay(false);


      const generationImages = pollStatus.generationImages;
      if (generationImages && generationImages.length > 0) {
        setGenerationImages(generationImages);
      }


      const generatedImageUrlName = imageSession.activeGeneratedImage;




      const timestamp = Date.now();
      const generatedURL = `/generations/${generatedImageUrlName}?${timestamp}`;

      // Append the generated image to the hidden container
      const hiddenContainer = document.getElementById('hidden-video-container');
      const imgElement = document.createElement('img');
      imgElement.src = generatedURL.startsWith('http') ? generatedURL : `${PROCESSOR_API_URL}${generatedURL}`;
      imgElement.style.display = 'none'; // Hide the image
      // hiddenContainer.appendChild(imgElement);



      const item_id = `item_${activeItemList.length}`;

      const stageDimensions = getCanvasDimensionsForAspectRatio(aspectRatio);
      const nImageList = [...activeItemList, {
        src: generatedURL, id: item_id, type: 'image',
        x: 0, y: 0, width: stageDimensions.width, height: stageDimensions.height
      }];


      setActiveItemList(nImageList);
      setIsGenerationPending(false);
      setCurrentView(CURRENT_TOOLBAR_VIEW.SHOW_DEFAULT_DISPLAY);
      toast.success(<div><FaCheck className='inline-flex mr-2' /> Generation completed successfully!</div>, {
        position: "bottom-center",
        className: "custom-toast",
      });


      updateCurrentLayerAndLayerList(layerList, updatedLayerIndex);

      setIsCanvasDirty(true);

      getUserAPI();

      return;

    } else if (pollStatus.status === 'FAILED') {
      setIsGenerationPending(false);
      setGenerationError(pollStatus.generationError);
      setCanvasActionLoading(false);
      toast.error(<div><FaTimes /> Generation failed.</div>, {
        position: "bottom-center",
        className: "custom-toast",
      });
      getUserAPI();
      return;
    } else {
      generationPollIntervalRef.current = setTimeout(() => {
        startGenerationPoll();
      }, 1000);
    }
  }

  async function startOutpaintPoll() {


    const selectedLayerId = currentLayer._id.toString();
    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }

    const pollStatusData = await axios.get(`${PROCESSOR_API_URL}/video_sessions/edit_status?id=${id}&layerId=${selectedLayerId}`, headers);

    const pollStatus = pollStatusData.data;

    if (pollStatus.status === 'COMPLETED') {

      const layerData = pollStatus.layer;
      const imageSession = layerData.imageSession;

 
      const newActiveItemList = imageSession.activeItemList;
      const generatedImageUrlName = imageSession.activeEditedImage;
      const generatedURL = `${generatedImageUrlName}`;
      const item_id = `item_${activeItemList.length}`;

      const stageDimensions = getCanvasDimensionsForAspectRatio(aspectRatio);
      const nImageList = [...activeItemList, { src: generatedURL, id: item_id, type: 'image',
        x: 0, y: 0, width: stageDimensions.width, height: stageDimensions.height
       }];

      const generationImages = pollStatus.generationImages;
      if (generationImages && generationImages.length > 0) {
        setGenerationImages(generationImages);
      }

      setCurrentView(CURRENT_TOOLBAR_VIEW.SHOW_DEFAULT_DISPLAY);
      setActiveItemList(nImageList);
      setIsOutpaintPending(false);
      setIsCanvasDirty(true);
      toast.success(<div><FaCheck className='inline-flex mr-2' /> Edit completed successfully!</div>, {
        position: "bottom-center",
        className: "custom-toast",
      });
      getUserAPI();
      return;
    } else if (pollStatus.status === 'FAILED') {
      setIsOutpaintPending(false);
      setOutpaintError("Failed to generate outpaint");
      toast.error(<div><FaTimes /> Outpaint failed.</div>, {
        position: "bottom-center",
        className: "custom-toast",
      });
      getUserAPI();
      return;
    } else {
      outpaintPollIntervalRef.current = setTimeout(() => {
        startOutpaintPoll();
      }, 1000);
    }
  }

  const layeredAudioGenerationPollIntervalRef = useRef(null);
  const numAudioLayersToPollRef = useRef(0);


  const startLayeredAudioGenerationPoll = async () => {
    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }
  
    const numLayers = numAudioLayersToPollRef.current;
  
    try {
      const pollStatusData = await axios.get(`${PROCESSOR_API_URL}/audio/layered_speech_generate_status?sessionId=${id}&numLayers=${numLayers}`, headers);
      const pollStatus = pollStatusData.data;
  
      if (pollStatus.generationStatus === 'COMPLETED') {
        setVideoSessionDetails(pollStatus.videoSession);
        setAudioGenerationPending(false);
  
        setCurrentCanvasAction(TOOLBAR_ACTION_VIEW.SHOW_PREVIEW_SPEECH_LAYERED_DISPLAY);
  
        toast.success(<div><FaCheck className='inline-flex mr-2' /> Layered speech generation completed successfully!</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
        getUserAPI();
        return;
      } else if (pollStatus.generationStatus === 'FAILED') {
        setAudioGenerationPending(false);
        toast.error(<div><FaTimes /> Layered speech generation failed.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
        getUserAPI();
        return;
      } else {
        layeredAudioGenerationPollIntervalRef.current = setTimeout(() => {
          startLayeredAudioGenerationPoll();
        }, 1000);
      }
    } catch (error) {
      console.error('Error in startLayeredAudioGenerationPoll:', error);
      setAudioGenerationPending(false);
      toast.error(<div><FaTimes /> Layered speech generation failed.</div>, {
        position: "bottom-center",
        className: "custom-toast",
      });
    }
  };



  const startAudioGenerationPoll = async () => {
    const sessionId = id;
    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }

    const pollStatusData = await axios.get(`${PROCESSOR_API_URL}/audio/generate_status?sessionId=${sessionId}`, headers);
    const pollStatus = pollStatusData.data;

    if (pollStatus.generationStatus === 'COMPLETED') {
      setVideoSessionDetails(pollStatus.videoSession);
      setAudioGenerationPending(false);

      const generationType = pollStatus.generationType;

      if (generationType === 'music') {
        
        setCurrentCanvasAction(TOOLBAR_ACTION_VIEW.SHOW_PREVIEW_MUSIC_DISPLAY);
      } else if (generationType === 'sound') {
        setCurrentCanvasAction(TOOLBAR_ACTION_VIEW.SHOW_PREVIEW_SOUND_DISPLAY);
      } else if (generationType === 'speech') {
        setCurrentCanvasAction(TOOLBAR_ACTION_VIEW.SHOW_PREVIEW_SPEECH_DISPLAY);
      }

      toast.success(<div><FaCheck className='inline-flex mr-2' /> Audio generation completed successfully!</div>, {
        position: "bottom-center",
        className: "custom-toast",
      });
      getUserAPI();


      return;
    } else if (pollStatus.generationStatus === 'FAILED') {
      setAudioGenerationPending(false);
      toast.error(<div><FaTimes /> Audio generation failed.</div>, {
        position: "bottom-center",
        className: "custom-toast",
      });
      getUserAPI();
      return;
    } else {
      audioGenerationPollIntervalRef.current = setTimeout(() => {
        startAudioGenerationPoll();
      }, 1000);
    }
  }

  const showTemplatesSelect = () => {
    setIsTemplateSelectViewSelected(!isTemplateSelectViewSelected);
  }

  const showAttestationDialog = () => { }

  const addImageItemToActiveList = (payload) => {
    setCurrentCanvasAction(TOOLBAR_ACTION_VIEW.SHOW_DEFAULT_DISPLAY);
    updateCurrentActiveLayer(payload);
  }

  const getRemoteTemplateData = (page) => {
    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }

    axios.get(`${PROCESSOR_API_URL}/utils/template_list?page=${page}`, headers)
      .then((response) => {
        const generatedImageUrlName = response.data.activeGeneratedImage;
        setTemplateOptionList(response.data);
        toast.success(<div><FaCheck className='inline-flex mr-2' /> Templates loaded successfully!</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      })
      .catch(() => {
        toast.error(<div><FaTimes /> Failed to load templates.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      });
  }

  const submitTemplateSearch = (query) => {
    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }

    axios.get(`${PROCESSOR_API_URL}/utils/search_template?query=${query}`, headers)
      .then((response) => {
        setTemplateOptionList(response.data);
        toast.success(<div><FaCheck className='inline-flex mr-2' /> Template search completed successfully!</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      })
      .catch(() => {
        toast.error(<div><FaTimes /> Failed to search templates.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      });
  }

  const startMaskGenerationPoll = () => {
    const sessionId = id;
    axios.get(`${PROCESSOR_API_URL}/video_sessions/generate_mask_status?sessionId=${sessionId}`)
      .then((response) => {
        const maskGeneration = response.data;
        if (maskGeneration.status === 'COMPLETED') {
          const sessionData = maskGeneration.session;
          setVideoSessionDetails(sessionData);

          const layerData = sessionData.layers.find(layer => layer._id.toString() === currentLayer._id.toString());
          const segmentationData = layerData.segmentation;
          setSegmentationData(segmentationData);
          setCanvasActionLoading(false);
          setIsCanvasDirty(true);
          toast.success(<div><FaCheck className='inline-flex mr-2' /> Mask generation completed successfully!</div>, {
            position: "bottom-center",
            className: "custom-toast",
          });
        } else {
          maskGenerationPollIntervalRef.current = setTimeout(() => {
            startMaskGenerationPoll();
          }, 1000);
        }
      })
      .catch(() => {
        toast.error(<div><FaTimes /> Failed to generate mask.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      });

  }

  useEffect(() => {
    if (currentCanvasAction === TOOLBAR_ACTION_VIEW.SHOW_SMART_SELECT_DISPLAY) {
      const headers = getHeaders();
      const originalStage = canvasRef.current.getStage();
      const clonedStage = originalStage.clone();

      clonedStage.find(node => node.id().startsWith('bbox_rect_')).forEach(node => {
        node.destroy();
      });

      clonedStage.find('Transformer').forEach(transformer => {
        transformer.destroy();
      });
      clonedStage.draw();
      const dataURL = clonedStage.toDataURL();
      const layerId = currentLayer._id.toString();
      const sessionPayload = {
        image: dataURL,
        sessionId: id,
        layerId: layerId
      };

      if (activeItemList.length === 0) {
        return;
      }

      if (activeItemList.length > 1) {
        const newItemId = `item_${activeItemList.length}`;

        const newItem = {
          src: dataURL,
          id: newItemId,
          type: 'image',
          x: 0,
          y: 0,
          width: STAGE_DIMENSIONS.width,
          height: STAGE_DIMENSIONS.height
        };
        const newItemList = [...activeItemList, newItem];
        setActiveItemList(newItemList);
        updateSessionLayerActiveItemList(newItemList);
      }

      setEnableSegmentationMask(false);

      axios.post(`${PROCESSOR_API_URL}/video_sessions/request_generate_mask`, sessionPayload, headers)
        .then(function (dataRes) {
          startMaskGenerationPoll();
          setEnableSegmentationMask(true);
          setCanvasActionLoading(true);
          toast.success(<div><FaCheck className='inline-flex mr-2' /> Mask generation request submitted successfully!</div>, {
            position: "bottom-center",
            className: "custom-toast",
          });
        })
        .catch(() => {
          toast.error(<div><FaTimes /> Failed to submit mask generation request.</div>, {
            position: "bottom-center",
            className: "custom-toast",
          });
        });
    }
  }, [currentCanvasAction]);

  const addImageToCanvas = (templateOption) => {


    const templateURL = `${PROCESSOR_API_URL}/templates/mm_final/${templateOption}`;
    const nImageList = [...activeItemList, { src: templateURL, id: `item_${nImageList.length}`, type: 'image' }];
    setActiveItemList(nImageList);
    setCurrentView(CURRENT_TOOLBAR_VIEW.SHOW_DEFAULT_DISPLAY);
    updateSessionLayerActiveItemList(nImageList);
  }

  const addTextBoxToCanvas = (payload) => {

    console.log("ADDING TEXT");
    console.log(payload);
    


    const nImageList = [...activeItemList, { ...payload, id: `item_${activeItemList.length}` }];



    const listPayload = nImageList.map(function(imageItem) {


      if (imageItem.type === 'image') {
        const imageSrc = imageItem.src;
        const questionMarkIndex = imageSrc.indexOf("?");
        // If "?" exists, slice the string up to its index; otherwise, return the URL as-is
       const imageSrcFormatted = questionMarkIndex !== -1 ? imageSrc.slice(0, questionMarkIndex) : imageSrc;
        // return the imageItem with the formatted src

        console.log(imageSrcFormatted);

        return {
          ...imageItem,
          src: imageSrcFormatted
        };

      } else {
        return imageItem;
      }
    })


    setActiveItemList(nImageList);
    updateSessionLayerActiveItemList(listPayload);
  }


  const createTextLayer = async (payload) => {


    const apiPayload = {
      sessionId: id,
      layerId: currentLayer._id.toString(),
      textItem: payload
    }
    const headers = getHeaders();

    const resData = await axios.post(`${PROCESSOR_API_URL}/video_sessions/add_text_to_active_list`, apiPayload, headers);

    const responsePayload = resData.data;

    const layerData = responsePayload.layer;
    const newActiveItemList = layerData.imageSession.activeItemList;

    setActiveItemList(newActiveItemList);




  }

  const showMoveAction = () => { }

  const showResizeAction = () => { }

  const showSaveAction = () => { }

  const showUploadAction = () => {
    setCurrentView(CURRENT_TOOLBAR_VIEW.SHOW_DEFAULT_DISPLAY);
    openAlertDialog(<UploadImageDialog setUploadURL={setUploadURL}

      aspectRatio={aspectRatio} />);
  }

  const setSelectedShape = (shapeKey) => {

    let shapeConfig;
    if (shapeKey === 'dialog') {
      shapeConfig = {
        x: 512, y: 200, width: 100, height: 50, fillColor: fillColor,
        strokeColor: strokeColor, strokeWidth: strokeWidthValue,
        pointerX: 512, pointerY: 270,
        xRadius: 50, yRadius: 20
      }
    } else {
      shapeConfig = {
        x: 512, y: 200, width: 200, height: 200, fillColor: fillColor, radius: 70,
        strokeColor: strokeColor, strokeWidth: strokeWidthValue
      }
    }

    const currentLayerList = [...activeItemList, {
      'type': 'shape',
      'shape': shapeKey,
      'config': shapeConfig,
      'id': `item_${activeItemList.length}`
    }];

    setActiveItemList(currentLayerList);
    setSelectedId(`item_${activeItemList.length}`);
    updateSessionLayerActiveItemList(currentLayerList);
  }

  const applyFilter = (index, filter, value) => {
    const nodeId = `item_${index}`;
    const stage = canvasRef.current.getStage();
    const imageNode = stage.findOne(`#${nodeId}`);
    if (!imageNode) {
      return;
    }
    imageNode.cache();
    imageNode.filters([filter]);

    if (filter === Konva.Filters.Blur) {
      imageNode.blurRadius(value);
    } else if (filter === Konva.Filters.Brighten) {
      imageNode.brightness(value);
    } else if (filter === Konva.Filters.Contrast) {
      imageNode.contrast(value);
    } else if (filter === Konva.Filters.Grayscale) {
      // No value needed for grayscale
    } else if (filter === Konva.Filters.HSL) {
      imageNode.hue(value * 360);
    } else if (filter === Konva.Filters.Invert) {
      // No value needed for invert
    } else if (filter === Konva.Filters.Pixelate) {
      imageNode.pixelSize(Math.round(value));
    } else if (filter === Konva.Filters.Posterize) {
      imageNode.levels(Math.round(value));
    } else if (filter === Konva.Filters.Sepia) {
      // No value needed for sepia
    } else if (filter === Konva.Filters.Solarize) {
      // No value needed for solarize
    } else if (filter === Konva.Filters.RGBA) {
      imageNode.alpha(value);
    }

    stage.batchDraw();
  };

  const applyFinalFilter = async (index, filter, value) => {
    const nodeId = `item_${index}`;
    const stage = canvasRef.current.getStage();
    const imageNode = stage.findOne(`#${nodeId}`);
    if (!imageNode) {
      return;
    }

    imageNode.cache();
    imageNode.filters([filter]);

    if (filter === Konva.Filters.Blur) {
      imageNode.blurRadius(value);
    } else if (filter === Konva.Filters.Brighten) {
      imageNode.brightness(value);
    } else if (filter === Konva.Filters.Contrast) {
      imageNode.contrast(value);
    } else if (filter === Konva.Filters.Grayscale) {
      // No value needed for grayscale
    } else if (filter === Konva.Filters.HSL) {
      imageNode.hue(value * 360);
    } else if (filter === Konva.Filters.Invert) {
      // No value needed for invert
    } else if (filter === Konva.Filters.Pixelate) {
      imageNode.pixelSize(Math.round(value));
    } else if (filter === Konva.Filters.Posterize) {
      imageNode.levels(Math.round(value));
    } else if (filter === Konva.Filters.Sepia) {
      // No value needed for sepia
    } else if (filter === Konva.Filters.Solarize) {
      // No value needed for solarize
    } else if (filter === Konva.Filters.RGBA) {
      imageNode.alpha(value);
    }

    stage.batchDraw();

    const updatedImageDataUrl = imageNode.toDataURL();

    const updatedItemList = activeItemList.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          src: updatedImageDataUrl,
        };
      }
      return item;
    });

    setActiveItemList(updatedItemList);
    updateSessionLayerActiveItemList(updatedItemList);
  };

  const handleBubbleChange = (newAttrs) => { };

  const combineCurrentLayerItems = async () => {
    const stage = canvasRef.current.getStage();

    // Hide transformers and masks
    const transformers = stage.find('Transformer');
    transformers.forEach(tr => tr.visible(false));
    const masks = stage.find('#maskGroup, #pencilGroup');
    masks.forEach(mask => mask.visible(false));

    const canvasDimensions = getCanvasDimensionsForAspectRatio(aspectRatio);

    // Export the stage to data URL
    const combinedImageDataUrl = stage.toDataURL({
      pixelRatio: 2
    });

    const combinedItem = {
      src: combinedImageDataUrl,
      id: `item_0`,
      type: 'image',
      x: 0,
      y: 0,
      width: canvasDimensions.width,
      height: canvasDimensions.height,
    };
    const updatedItemList = [combinedItem];
    setActiveItemList(updatedItemList);
    updateSessionLayerActiveItemList(updatedItemList);
    setSelectedId('item_0');
  };




  const [canvasDimensions, setCanvasDimensions] = useState({ width: 1024, height: 1024 });
  useEffect(() => {
    if (aspectRatio === '16:9') {
      setCanvasDimensions({ width: 1792, height: 1024 });
    } else if (aspectRatio === '9:16') {
      setCanvasDimensions({ width: 1024, height: 1792 });
    } else {
      setCanvasDimensions({ width: 1024, height: 1024 });
    }
  }, [aspectRatio]);

  const submitGenerateMusicRequest = (payload) => {


    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }
    payload.sessionId = id;

    axios.post(`${PROCESSOR_API_URL}/audio/request_generate_audio`, payload, headers)
      .then((response) => {
        const audioGeneration = response.data;
        setAudioGenerationPending(true);
        startAudioGenerationPoll(audioGeneration);
        toast.success(<div><FaCheck className='inline-flex mr-2' /> Audio generation request submitted successfully!</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      })
      .catch(() => {
        toast.error(<div><FaTimes /> Failed to submit audio generation request.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      });
  }

  let viewDisplay = <span />;

  if (currentView === CURRENT_TOOLBAR_VIEW.SHOW_TEMPLATES_DISPLAY) {
    viewDisplay = (
      <SelectTemplate getRemoteTemplateData={getRemoteTemplateData}
        templateOptionList={templateOptionList} addImageToCanvas={addImageToCanvas}
        resetCurrentView={resetCurrentView}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        submitSearch={submitTemplateSearch}
      />
    )
  }

  const exportAnimationFrames = async (updatedItemList) => { };


  const submitAddBatchTrackToProject = (payload) => {

    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }
    const sessionId = id;

    let requestPayload = {
      sessionId,
      audioLayers: payload
    }


    axios.post(`${PROCESSOR_API_URL}/audio/add_track_list_to_project`, requestPayload, headers)
      .then((response) => {
        const sessionData = response.data;
        if (sessionData && sessionData.videoSession) {
          setVideoSessionDetails(sessionData.videoSession);
          setCurrentCanvasAction(TOOLBAR_ACTION_VIEW.SHOW_DEFAULT_DISPLAY);
          toast.success(<div><FaCheck className='inline-flex mr-2' /> Track added to project successfully!</div>, {
            position: "bottom-center",
            className: "custom-toast",
          });
          setIsCanvasDirty(true);
        }
      })
      .catch(() => {
        toast.error(<div><FaTimes /> Failed to add track to project.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      });

    
  }

  const submitAddTrackToProject = (index, payload) => {


    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }
    const sessionId = id;
    const audioLayers = videoSessionDetails.audioLayers;
    const latestAudioLayer = audioLayers[audioLayers.length - 1];
    const layerId = latestAudioLayer._id.toString();

    let requestPayload = {
      sessionId,
      trackIndex: index,
      ...payload
    }

    if (!requestPayload.audioLayerId) {
      requestPayload.audioLayerId = layerId;
    }

    axios.post(`${PROCESSOR_API_URL}/audio/add_track_to_project`, requestPayload, headers)
      .then((response) => {
        const sessionData = response.data;
        if (sessionData && sessionData.videoSession) {
          setVideoSessionDetails(sessionData.videoSession);
          setCurrentCanvasAction(TOOLBAR_ACTION_VIEW.SHOW_DEFAULT_DISPLAY);
          toast.success(<div><FaCheck className='inline-flex mr-2' /> Track added to project successfully!</div>, {
            position: "bottom-center",
            className: "custom-toast",
          });
          setIsCanvasDirty(true);
        }
      })
      .catch(() => {
        toast.error(<div><FaTimes /> Failed to add track to project.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      });
  }

  useEffect(() => {
    if (cursorSelectOptionVisible) {
      const selectedId = activeItemList[activeItemList.length - 1].id;
      setSelectedId(`item_${selectedId + 1}`);
      setCurrentView(CURRENT_TOOLBAR_VIEW.SHOW_CURSOR_SELECT_DISPLAY)
    }
  }, [cursorSelectOptionVisible]);

  const selectImageFromLibrary = (imageItem) => {
    const newItemId = `item_${activeItemList.length}`;

    const canvasDimensions = getCanvasDimensionsForAspectRatio(aspectRatio);
    const newItem = {
      src: imageItem,
      id: newItemId,
      type: 'image',
      x: 0,
      y: 0,
      width: canvasDimensions.width,
      height: canvasDimensions.height
    };
    const newItemList = [...activeItemList, newItem];
    setActiveItemList(newItemList);
    updateSessionLayerActiveItemList(newItemList);
    setCurrentCanvasAction(TOOLBAR_ACTION_VIEW.SHOW_DEFAULT_DISPLAY);

    currentLayer.imageSession.activeItemList = newItemList;
    updateCurrentLayer(currentLayer);




    toast.success(<div><FaCheck className='inline-flex mr-2' /> Image added from library successfully!</div>, {
      position: "bottom-center",
      className: "custom-toast",
    });
  }

  const resetImageLibrary = () => {
    setCurrentCanvasAction(TOOLBAR_ACTION_VIEW.SHOW_DEFAULT_DISPLAY);
  }

  let canvasInternalLoading = <span />;



  const canvasWidth = canvasDimensions.width;
  if (canvasActionLoading) {
    canvasInternalLoading = (
      <div className={`absolute t-0 pt-[150px] w-[${canvasWidth}px]  z-10`}>
        <LoadingImageTransparent />
      </div>
    )
  }


  const removeAIVideoLayer = async () => {
    const headers = getHeaders();
    const payload = {
      sessionId: id,
      layerId: currentLayer._id.toString()
    }

    const responseData = await axios.post(`${PROCESSOR_API_URL}/video_sessions/remove_ai_video_layer`, payload, headers);

    const removeResponse = responseData.data;
    if (removeResponse) {

      const { session, layer } = removeResponse;
      // setVideoSessionDetails(session);
      const layerList = session.layers;
      const currentNewLayerIndex = layerList.findIndex(l => l._id.toString() === layer._id.toString());
      // updateCurrentLayerInSessionList(layer);

      updateCurrentLayerAndLayerList(layerList, currentNewLayerIndex);
      setActiveItemList(layer.imageSession.activeItemList);
      setAiVideoLayer(null);


    }
  }

  const requestRegenerateSubtitles = async () => {
    toast.success(<div><FaCheck className='inline-flex mr-2' />Requested regenrate subtitles.</div>, {
      position: "bottom-center",
      className: "custom-toast",
    });


    const headers = getHeaders();

    const payload = {
      sessionId: id,
      realignAudio: true,
    }
    axios.post(`${PROCESSOR_API_URL}/video_sessions/request_regenerate_subtitles`, payload, headers).then(function (dataRes) {
      setIsCanvasDirty(true);
    });
  }

  const requestReAlignLayersToSpeechAndRegenerateSubtitles = async () => {

    const headers = getHeaders();
    const payload = {
      sessionId: id,
      realignAudio: true,
    }
    axios.post(`${PROCESSOR_API_URL}/video_sessions/request_realign_layers_to_speech_and_regen_sub`, payload, headers).then(function (dataRes) {
      setIsCanvasDirty(true);
      toast.success(<div><FaCheck className='inline-flex mr-2' /> Request to realign layers to speech submitted successfully!</div>, {
        position: "bottom-center",
        className: "custom-toast",
      });
    })
  }

  const requestRealignToAiVideoAndLayers = async () => {
    const headers = getHeaders();
    const payload = {
      sessionId: id,
    }
    axios.post(`${PROCESSOR_API_URL}/video_sessions/request_realign_to_ai_video_and_layers`, payload, headers).then(function (dataRes) {
      setIsCanvasDirty(true);
      toast.success(<div><FaCheck className='inline-flex mr-2' /> Request to realign layers to AI video submitted successfully!</div>, {
        position: "bottom-center",
        className: "custom-toast",
      });
    })

  }


  const requestRegenerateAnimations = async () => {
    const headers = getHeaders();
    const payload = {
      sessionId: id,
    }
    toast.success(<div><FaCheck className='inline-flex mr-2' /> Request to regenerate animations submitted successfully!</div>, {
      position: "bottom-center",
      className: "custom-toast",
    });

    axios.post(`${PROCESSOR_API_URL}/video_sessions/request_regenerate_animations`, payload, headers).then(function (dataRes) {
      setIsCanvasDirty(true);
    })
  }


  const requestAddAudioLayerFromLibrary = (audioItem) => {

    const payload = {
      sessionId: id,
      audioItem: audioItem,
    };

    const headers = getHeaders();
    axios.post(`${PROCESSOR_API_URL}/video_sessions/add_audio_from_library`, payload, headers).then(function (dataRes) {
      const response = dataRes.data;
      const sessionDetails = response.sessionDetails;
      if (sessionDetails) {
        setVideoSessionDetails(sessionDetails);
        toast.success(<div><FaCheck className='inline-flex mr-2' /> Audio added to project successfully!</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
        resetImageLibrary();
      }
    });

  }

  const addSelectedAiVideoToLayer = (payload) => {
    const { video, trimScene } = payload;
    const videoURL = video.url;

    const requestPayload = {
      sessionId: id,
      videoURL: videoURL,
      trimScene: trimScene,
      layerId: currentLayer._id.toString()
    }
    const headers = getHeaders();

    axios.post(`${PROCESSOR_API_URL}/video_sessions/add_ai_video_layer`, requestPayload, headers).then(function (dataRes) {
      const response = dataRes.data;
      toast.success(<div><FaCheck className='inline-flex mr-2' /> Video added to project successfully!</div>, {
        position: "bottom-center",
        className: "custom-toast",
      });
      resetImageLibrary();
      const { session, layer } = response;
      const newLayers = session.layers;
      const currentLayerIndex = newLayers.findIndex(l => l._id.toString() === layer._id.toString());
      updateCurrentLayerAndLayerList(newLayers, currentLayerIndex);
    });
  }

  if (currentLayer && currentLayer.imageSession && currentLayer.imageSession.activeItemList) {
    if (currentLayer.imageSession.generationStatus === 'PENDING') {
      viewDisplay = <LoadingImage />;
    } else {
      if (currentCanvasAction === TOOLBAR_ACTION_VIEW.SHOW_LIBRARY_DISPLAY) {
        viewDisplay = (
          <LibraryHome
            generationImages={generationImages}
            addImageItemToActiveList={addImageItemToActiveList}
            selectImageFromLibrary={selectImageFromLibrary}
            resetImageLibrary={resetImageLibrary}
            onSelectMusic={requestAddAudioLayerFromLibrary}
            onSelectVideo={addSelectedAiVideoToLayer}
          />
        )
      } else {
        viewDisplay = (
          <div>
            {canvasInternalLoading}
            <VideoCanvasContainer ref={canvasRef}
              maskGroupRef={maskGroupRef}
              sessionDetails={videoSessionDetails}
              activeItemList={activeItemList}
              setActiveItemList={setActiveItemList}
              editBrushWidth={editBrushWidth}
              currentView={currentView}
              editMasklines={editMasklines}
              setEditMaskLines={setEditMaskLines}
              currentCanvasAction={currentCanvasAction}
              setCurrentCanvasAction={setCurrentCanvasAction}
              fillColor={fillColor}
              strokeColor={strokeColor}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              buttonPositions={buttonPositions}
              setButtonPositions={setButtonPositions}
              selectedLayerType={selectedLayerType}
              setSelectedLayerType={setSelectedLayerType}
              applyFilter={applyFilter}
              applyFinalFilter={applyFinalFilter}
              onChange={handleBubbleChange}
              pencilColor={pencilColor}
              pencilWidth={pencilWidth}
              eraserWidth={eraserWidth}
              sessionId={id}
              selectedLayerId={selectedLayerId}
              exportAnimationFrames={exportAnimationFrames}
              currentLayerSeek={currentLayerSeek}
              currentLayer={currentLayer}
              updateSessionActiveItemList={updateSessionLayerActiveItemList}
              selectedLayerSelectShape={selectedLayerSelectShape}
              setCurrentView={setCurrentView}
              isLayerSeeking={isLayerSeeking}
              setEnableSegmentationMask={setEnableSegmentationMask}
              enableSegmentationMask={enableSegmentationMask}
              segmentationData={segmentationData}
              setSegmentationData={setSegmentationData}
              isExpressGeneration={isExpressGeneration}
              removeVideoLayer={removeAIVideoLayer}
              aspectRatio={aspectRatio}
              isAIVideoGenerationPending={isAIVideoGenerationPending}
              toggleStageZoom={toggleStageZoom}
              stageZoomScale={stageZoomScale}
              requestRegenerateSubtitles={requestRegenerateSubtitles}
              displayZoomType={displayZoomType}
              aiVideoLayer={aiVideoLayer}
              requestRegenerateAnimations={requestRegenerateAnimations}
              requestRealignLayers={requestReAlignLayersToSpeechAndRegenerateSubtitles}
              totalDuration={totalDuration}
              selectedEditModelValue={selectedEditModelValue}
              downloadCurrentFrame={downloadCurrentFrame}
              createTextLayer={createTextLayer}
              requestRealignToAiVideoAndLayers={requestRealignToAiVideoAndLayers}
            />

          </div>
        )
      }
    }
  }


  // VideoEditorContainer.js

  const [isUpdateDefaultsPending, setIsUpdateDefaultsPending] = useState(false);

  const submitUpdateSessionDefaults = (defaultPayload) => {
    setIsUpdateDefaultsPending(true);
    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return Promise.reject(new Error('No headers'));
    }
    const payload = {
      sessionId: id,
      defaults: defaultPayload
    };

    return axios.post(`${PROCESSOR_API_URL}/video_sessions/update_defaults`, payload, headers)
      .then((response) => {
        const updatedSession = response.data;
        const sessionData = updatedSession.videoSession;
        setVideoSessionDetails(sessionData);
        setIsUpdateDefaultsPending(false);
        toast.success(<div><FaCheck className='inline-flex mr-2' /> Session defaults updated successfully!</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      })
      .catch((error) => {
        toast.error(<div><FaTimes /> Failed to update session defaults.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
        setIsUpdateDefaultsPending(false);
        return Promise.reject(error);
      });
  }




  const submitGenerateLayeredSpeechRequest = (data) => {
    const payload = {
      ...data,
      fontSize: 40,
      fontColor: '#f5f5f5',
      fontFamily: 'Times New Roman',
      backgroundColor: '#030712',
      videoSessionId: id,
    };
  
    const numLayers = data.promptList.length;
    numAudioLayersToPollRef.current = numLayers;
  
    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }
  
    setAudioGenerationPending(true);
  
    axios.post(`${PROCESSOR_API_URL}/video_sessions/request_generate_layered_speech`, payload, headers)
      .then((response) => {
        toast.success(<div><FaCheck className='inline-flex mr-2' /> Layered speech generation request submitted successfully!</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
  
        startLayeredAudioGenerationPoll();
      })
      .catch((error) => {
        setAudioGenerationPending(false);
        toast.error(<div><FaTimes /> Failed to submit layered speech generation request.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      });
  };




  const setAdvancedSessionTheme = (payload) => {
    setIsUpdateDefaultsPending(true);
    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }

    payload = {
      sessionId: id,
      aspectRatio: aspectRatio,
      ...payload
    };

    axios.post(`${PROCESSOR_API_URL}/video_sessions/set_advanced_theme`, payload, headers)
      .then((response) => {
        const updatedSessionContainer = response.data;
        const updatedSession = updatedSessionContainer.sessionDetails;
        setVideoSessionDetails(updatedSession);
        toast.success(<div><FaCheck className='inline-flex mr-2' /> Advanced theme updated successfully!</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
        setIsUpdateDefaultsPending(false);
      })
      .catch(() => {
        toast.error(<div><FaTimes /> Failed to update advanced theme.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
        setIsUpdateDefaultsPending(false);
      });

  }

  const startAIVideoLayerGenerationPoll = async () => {
    const selectedLayerId = currentLayer._id.toString();
    const payload = {
      sessionId: id,
      layerId: selectedLayerId
    }

    const headers = getHeaders();

    const pollResData = await axios.post(`${PROCESSOR_API_URL}/video_sessions/generate_ai_video_status`, payload, headers);
    const pollRes = pollResData.data;


    if (pollRes.status === 'COMPLETED') {
      const sessionData = pollRes.session;

      // console.log()
      setIsAIVideoGenerationPending(false);
      // setVideoSessionDetails(sessionData);
      const layerData = sessionData.layers.find(layer => layer._id.toString() === selectedLayerId);
      const newLayers = sessionData.layers;
      const updatedLayerIndex = newLayers.findIndex(layer => layer._id.toString() === selectedLayerId);
 
     const hiddenContainer = document.getElementById('hidden-video-container');
     const videoSrc = `${STATIC_CDN_URL}/${currentLayer.aiVideoRemoteLink}`;

     const video = document.createElement('video');
     video.src = videoSrc;
     video.preload = 'auto';
     video.style.display = 'none'; // Hide the video
     hiddenContainer.appendChild(video);


      updateCurrentLayerAndLayerList(newLayers, updatedLayerIndex);
      setIsCanvasDirty(true);
      getUserAPI();

    } else if (pollRes.status === 'FAILED') {
      setIsAIVideoGenerationPending(false);
      // setGenerationError(pollRes.error);
      toast.error(<div><FaTimes /> AI Video generation failed.</div>, {
        position: "bottom-center",
        className: "custom-toast",
      });

      getUserAPI();


    } else {
      aiVideoGenerationPollIntervalRef.current = setTimeout(() => {
        startAIVideoLayerGenerationPoll();
      }, 1000);

    }

  }

  const submitGenerateNewAIVideoRequest = (requestConfig) => {

    setIsAIVideoGenerationPending(true);
    const payload = {
      model: selectedVideoGenerationModel,
      prompt: videoPromptText,
      currentLayerId: currentLayer._id.toString(),
      videoSessionId: id,
      aspectRatio: aspectRatio,
      ...requestConfig,
    }

    setGenerationError(null);

    const headers = getHeaders();
    if (!headers) {
      showLoginDialog();
      return;
    }
    axios.post(`${PROCESSOR_API_URL}/video_sessions/request_generate_custom_video`, payload, headers)
      .then((resData) => {
        const response = resData.data;
        const updatedPrompt = response.prompt;
        setCurrentLayerDefaultPrompt(updatedPrompt);

        startAIVideoLayerGenerationPoll();

        // startAIVideoLayerGenerationPoll();
        toast.success(<div><FaCheck className='inline-flex mr-2' /> Generation request submitted successfully!</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      })
      .catch(error => {
        setGenerationError(error.message);
        toast.error(<div><FaTimes /> Failed to submit generation request.</div>, {
          position: "bottom-center",
          className: "custom-toast",
        });
      });


  }

  const [mimialEditorDisplay, setMinimalEditorDisplay] = useState(true);
  const onToggleEditorMinimalDisplay = () => {
    setMinimalEditorDisplay(!mimialEditorDisplay);
  }

  if (displayZoomType === 'fill') {

    let editorToolbarDisplay = <span />;
    if (mimialEditorDisplay) {

      editorToolbarDisplay = <div className='w-[2%] inline-block bg-gray-800 '>
        <VideoEditorToolbarMinimal
          onToggleDisplay={onToggleEditorMinimalDisplay}
        />
      </div>
    } else {
      editorToolbarDisplay = (
        <div className='w-[18%] inline-block bg-cyber-black '>
          <VideoEditorToolbar promptText={promptText}
            setPromptText={setPromptText}
            setVideoPromptText={setVideoPromptText}
            videoPromptText={videoPromptText}
            submitGenerateRequest={submitGenerateRequest}
            submitOutpaintRequest={submitOutpaintRequest}
            showAttestationDialog={showAttestationDialog}
            selectedChain={selectedChain}
            setSelectedChain={setSelectedChain}
            selectedAllocation={selectedAllocation}
            setSelectedAllocation={setSelectedAllocation}
            showTemplatesSelect={showTemplatesSelect}
            addTextBoxToCanvas={addTextBoxToCanvas}
            showMask={showMask}
            setShowMask={setShowMask}
            editBrushWidth={editBrushWidth}
            setEditBrushWidth={setEditBrushWidth}
            setCurrentViewDisplay={setCurrentViewDisplay}
            currentViewDisplay={currentView}
            textConfig={textConfig}
            setTextConfig={setTextConfig}
            activeItemList={activeItemList}
            setActiveItemList={setActiveItemList}
            selectedGenerationModel={selectedGenerationModel}
            setSelectedGenerationModel={setSelectedGenerationModel}
            selectedEditModel={selectedEditModel}
            setSelectedEditModel={setSelectedEditModel}
            isGenerationPending={isGenerationPending}
            isOutpaintPending={isOutpaintPending}
            isPublicationPending={isPublicationPending}
            setSelectedShape={setSelectedShape}
            fillColor={fillColor}
            setFillColor={setFillColor}
            strokeColor={strokeColor}
            setStrokeColor={setStrokeColor}
            strokeWidthValue={strokeWidthValue}
            setStrokeWidthValue={setStrokeWidthValue}
            generationError={generationError}
            outpaintError={outpaintError}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            exportAnimationFrames={exportAnimationFrames}
            showMoveAction={showMoveAction}
            showResizeAction={showResizeAction}
            showSaveAction={showSaveAction}
            showUploadAction={showUploadAction}
            pencilWidth={pencilWidth}
            setPencilWidth={setPencilWidth}
            pencilColor={pencilColor}
            setPencilColor={setPencilColor}
            eraserWidth={eraserWidth}
            setEraserWidth={setEraserWidth}
            cursorSelectOptionVisible={cursorSelectOptionVisible}
            setCursorSelectOptionVisible={setCursorSelectOptionVisible}
            setCurrentCanvasAction={setCurrentCanvasAction}
            currentCanvasAction={currentCanvasAction}
            selectedLayerSelectShape={selectedLayerSelectShape}
            setSelectedLayerSelectShape={setSelectedLayerSelectShape}
            updateSessionLayerActiveItemList={updateSessionLayerActiveItemList}
            updateSessionLayerActiveItemListAnimations={updateSessionLayerActiveItemListAnimations}
            eraserOptionsVisible={eraserOptionsVisible}
            submitGenerateMusicRequest={submitGenerateMusicRequest}
            audioLayers={videoSessionDetails.audioLayers}
            audioGenerationPending={audioGenerationPending}
            submitAddTrackToProject={submitAddTrackToProject}
            combineCurrentLayerItems={combineCurrentLayerItems}
            showAddAudioToProjectDialog={showAddAudioToProjectDialog}
            sessionDetails={videoSessionDetails}
            submitUpdateSessionDefaults={submitUpdateSessionDefaults}
            hideItemInLayer={toggleHideItemInLayer}
            applyAnimationToAllLayers={applyAnimationToAllLayers}
            submitGenerateLayeredSpeechRequest={submitGenerateLayeredSpeechRequest}
            currentDefaultPrompt={currentLayerDefaultPrompt}
            submitGenerateRecreateRequest={submitGenerateRecreateRequest}
            submitGenerateNewRequest={submitGenerateNewRequest}
            setShowCreateNewPromptDisplay={setShowCreateNewPromptDisplay}
            showCreateNewPromptDisplay={showCreateNewPromptDisplay}
            showCreateNewPrompt={showCreateNewPrompt}
            submitGenerateNewVideoRequest={submitGenerateNewAIVideoRequest}
            selectedVideoGenerationModel={selectedVideoGenerationModel}
            setSelectedVideoGenerationModel={setSelectedVideoGenerationModel}
            aiVideoGenerationPending={isAIVideoGenerationPending}
            aspectRatio={aspectRatio}
            showToggleCollapseToolbar={true}
            onToggleDisplay={onToggleEditorMinimalDisplay}
            setAdvancedSessionTheme={setAdvancedSessionTheme}
            requestAddAudioLayerFromLibrary={requestAddAudioLayerFromLibrary}
            isUpdateDefaultsPending={isUpdateDefaultsPending}
            selectedEditModelValue={selectedEditModelValue}
            submitAddBatchTrackToProject={submitAddBatchTrackToProject}

          />
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="custom-toast-container"
            toastClassName="custom-toast"
            bodyClassName="custom-toast-body"
          />

        </div>
      )
    }

    return (
      <div className='block'>
        <div className='text-center w-[98%] inline-block h-[100vh] overflow-scroll m-auto  mb-8 '>
          {viewDisplay}
        </div>
        {editorToolbarDisplay}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="custom-toast-container"
          toastClassName="custom-toast"
          bodyClassName="custom-toast-body"
        />
      </div>
    )
  }


  return (
    <div className='block'>
      <div className='text-center w-[82%] inline-block h-[100vh] overflow-scroll m-auto  mb-8 '>
        {viewDisplay}
      </div>
      <div className='w-[18%] inline-block bg-cyber-black '>
        <VideoEditorToolbar promptText={promptText}
          setPromptText={setPromptText}
          setVideoPromptText={setVideoPromptText}
          videoPromptText={videoPromptText}
          submitGenerateRequest={submitGenerateRequest}
          submitOutpaintRequest={submitOutpaintRequest}
          showAttestationDialog={showAttestationDialog}
          selectedChain={selectedChain}
          setSelectedChain={setSelectedChain}
          selectedAllocation={selectedAllocation}
          setSelectedAllocation={setSelectedAllocation}
          showTemplatesSelect={showTemplatesSelect}
          addTextBoxToCanvas={addTextBoxToCanvas}
          showMask={showMask}
          setShowMask={setShowMask}
          editBrushWidth={editBrushWidth}
          setEditBrushWidth={setEditBrushWidth}
          setCurrentViewDisplay={setCurrentViewDisplay}
          currentViewDisplay={currentView}
          textConfig={textConfig}
          setTextConfig={setTextConfig}
          activeItemList={activeItemList}
          setActiveItemList={setActiveItemList}
          selectedGenerationModel={selectedGenerationModel}
          setSelectedGenerationModel={setSelectedGenerationModel}
          selectedEditModel={selectedEditModel}
          setSelectedEditModel={setSelectedEditModel}
          isGenerationPending={isGenerationPending}
          isOutpaintPending={isOutpaintPending}
          isPublicationPending={isPublicationPending}
          setSelectedShape={setSelectedShape}
          fillColor={fillColor}
          setFillColor={setFillColor}
          strokeColor={strokeColor}
          setStrokeColor={setStrokeColor}
          strokeWidthValue={strokeWidthValue}
          setStrokeWidthValue={setStrokeWidthValue}
          generationError={generationError}
          outpaintError={outpaintError}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          exportAnimationFrames={exportAnimationFrames}
          showMoveAction={showMoveAction}
          showResizeAction={showResizeAction}
          showSaveAction={showSaveAction}
          showUploadAction={showUploadAction}
          pencilWidth={pencilWidth}
          setPencilWidth={setPencilWidth}
          pencilColor={pencilColor}
          setPencilColor={setPencilColor}
          eraserWidth={eraserWidth}
          setEraserWidth={setEraserWidth}
          cursorSelectOptionVisible={cursorSelectOptionVisible}
          setCursorSelectOptionVisible={setCursorSelectOptionVisible}
          setCurrentCanvasAction={setCurrentCanvasAction}
          currentCanvasAction={currentCanvasAction}
          selectedLayerSelectShape={selectedLayerSelectShape}
          setSelectedLayerSelectShape={setSelectedLayerSelectShape}
          updateSessionLayerActiveItemList={updateSessionLayerActiveItemList}
          updateSessionLayerActiveItemListAnimations={updateSessionLayerActiveItemListAnimations}
          eraserOptionsVisible={eraserOptionsVisible}
          submitGenerateMusicRequest={submitGenerateMusicRequest}
          audioLayers={videoSessionDetails.audioLayers}
          audioGenerationPending={audioGenerationPending}
          submitAddTrackToProject={submitAddTrackToProject}
          combineCurrentLayerItems={combineCurrentLayerItems}
          showAddAudioToProjectDialog={showAddAudioToProjectDialog}
          sessionDetails={videoSessionDetails}
          submitUpdateSessionDefaults={submitUpdateSessionDefaults}
          isUpdateDefaultsPending={isUpdateDefaultsPending}
          hideItemInLayer={toggleHideItemInLayer}
          applyAnimationToAllLayers={applyAnimationToAllLayers}
          submitGenerateLayeredSpeechRequest={submitGenerateLayeredSpeechRequest}
          currentDefaultPrompt={currentLayerDefaultPrompt}
          submitGenerateRecreateRequest={submitGenerateRecreateRequest}
          submitGenerateNewRequest={submitGenerateNewRequest}
          setShowCreateNewPromptDisplay={setShowCreateNewPromptDisplay}
          showCreateNewPromptDisplay={showCreateNewPromptDisplay}
          showCreateNewPrompt={showCreateNewPrompt}
          submitGenerateNewVideoRequest={submitGenerateNewAIVideoRequest}
          selectedVideoGenerationModel={selectedVideoGenerationModel}
          setSelectedVideoGenerationModel={setSelectedVideoGenerationModel}
          aiVideoGenerationPending={isAIVideoGenerationPending}
          aspectRatio={aspectRatio}
          setAdvancedSessionTheme={setAdvancedSessionTheme}
          requestAddAudioLayerFromLibrary={requestAddAudioLayerFromLibrary}
          selectedEditModelValue={selectedEditModelValue}
          submitAddBatchTrackToProject={submitAddBatchTrackToProject}

        />


      </div>
    </div>
  )
}

