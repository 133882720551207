import React, { useState, useEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import CommonButton from '../common/CommonButton.tsx';
import { useParams, useNavigate } from 'react-router-dom';

import { FaChevronCircleDown, FaCog } from 'react-icons/fa';

import { useUser } from '../../contexts/UserContext.js';
import { useColorMode } from '../../contexts/ColorMode.js';

import SingleSelect from '../common/SingleSelect.js';

import { useAlertDialog } from '../../contexts/AlertDialogContext.js';

import axios from 'axios';

import { getHeaders } from '../../utils/web.js';
import ProgressIndicator from '../quick_editor/ProgressIndicator.js'; // Import the ProgressIndicator component

const API_SERVER = process.env.REACT_APP_PROCESSOR_API;

// Move Typewriter component outside of OneshotEditor
function Typewriter({ text, delay = 100 }) {
  const [displayedText, setDisplayedText] = useState('');
  const hasAnimatedRef = useRef(false);

  useEffect(() => {
    if (hasAnimatedRef.current) return; // Prevent re-running the animation
    hasAnimatedRef.current = true;

    let currentIndex = 0;
    const interval = setInterval(() => {
      setDisplayedText(text.slice(0, currentIndex + 1));
      currentIndex++;
      if (currentIndex === text.length) {
        clearInterval(interval);
      }
    }, delay);

    return () => clearInterval(interval);

  }, []); // Empty dependency array ensures this runs only once

  return <span>{displayedText}</span>;
}

export default function OneshotEditor() {
  const [promptText, setPromptText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGenerationPending, setIsGenerationPending] = useState(false);
  const [expressGenerationStatus, setExpressGenerationStatus] = useState(null);
  const [videoLink, setVideoLink] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showResultDisplay, setShowResultDisplay] = useState(false);

  const { openAlertDialog, closeAlertDialog } = useAlertDialog();
  const { user } = useUser();

  const { colorMode } = useColorMode();

  const { id } = useParams();
  const navigate = useNavigate();

  // Add state variables for animation
  const [animateHeading, setAnimateHeading] = useState(false);
  const [showSubheading, setShowSubheading] = useState(false);

  // State variables for the selected duration and aspect ratio
  const [selectedDuration, setSelectedDuration] = useState('60'); // default to 60 seconds
  const [selectedAspectRatio, setSelectedAspectRatio] = useState('16:9'); // default to 16:9

  // Trigger animations on component mount
  useEffect(() => {
    const animationPlayed = localStorage.getItem('animationPlayed');
    if (!animationPlayed) {
      setTimeout(() => {
        setAnimateHeading(true);
        setTimeout(() => {
          setShowSubheading(true);
          // Mark the animation as played
          localStorage.setItem('animationPlayed', 'true');
        }, 500); // Delay before showing subheading
      }, 500); // Initial delay before moving up the heading
    } else {
      // If animation has already played, set the states immediately
      setAnimateHeading(true);
      setShowSubheading(true);
    }
  }, []);

  const resetForm = () => {
    setPromptText('');
    setShowResultDisplay(false);
    setErrorMessage(null);
    setVideoLink(null);
    setExpressGenerationStatus(null);
  };

  // Reset form on ID change
  useEffect(() => {
    if (id) {
    resetForm();
    getSessionDetails(); // Fetch session details for the new ID
    } else {

      
    }

  }, [id]);

  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {


    if (!user) {
      setIsDisabled(true);
      return;
    }

    let isValidUser = user.isPremiumUser || user.isAdminUser;

    console.log("IS VALID USER " + isValidUser);

    if (!user._id || (!isValidUser)) {
      setIsDisabled(true);
    } else {
      if (isValidUser) {
        setIsDisabled(false); 
      }
    }
  }, [id, user]);


  console.log(user);
  console.log(isDisabled);

  const getSessionDetails = async () => {
    const headers = getHeaders();
    // Implement fetching session details
    const resData = await axios.get(`${API_SERVER}/quick_session/details?sessionId=${id}`, headers);
    const response = resData.data;

    console.log(response);
  };

  // Reset form after successful submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!promptText.trim()) {
      alert('Please enter theme text before submitting.');
      return;
    }

    setIsSubmitting(true);
    setIsGenerationPending(true);
    setShowResultDisplay(true);
    setErrorMessage(null);
    setVideoLink(null);
    setExpressGenerationStatus(null);

    const payload = {
      prompt: promptText,
      sessionID: id,
      duration: selectedDuration,
      aspectRatio: selectedAspectRatio,
    };

    if (!id) {
      return;
    }

    const headers = getHeaders();

    if (!headers) {
      return;
    }

    try {
      const response = await axios.post(`${API_SERVER}/vidgpt/create`, payload, headers);
      const resData = response.data;

      console.log(resData);
      pollGenerationStatus();
    } catch (error) {
      console.error('Error submitting theme text:', error);
      setErrorMessage({ error: 'An unexpected error occurred.' });
      setIsGenerationPending(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const pollGenerationStatus = () => {
    const interval = setInterval(async () => {
      try {
        const headers = getHeaders();
        const response = await axios.get(`${API_SERVER}/quick_session/status?sessionId=${id}`, headers);
        const resData = response.data;

        setExpressGenerationStatus(resData.expressGenerationStatus);

        if (resData.status === 'COMPLETED') {
          clearInterval(interval);
          const videoLink = resData.videoLink;
          setIsGenerationPending(false);
          setVideoLink(videoLink);
        } else if (resData.status === 'FAILED') {
          clearInterval(interval);
          setIsGenerationPending(false);
          setErrorMessage({ error: 'Video generation failed.' });
        }
      } catch (error) {
        console.error('Error fetching generation status:', error);
        clearInterval(interval);
        setIsGenerationPending(false);
        setErrorMessage({ error: 'An unexpected error occurred while fetching status.' });
      }
    }, 5000); // Poll every 5 seconds
  };

  const viewInStudio = () => {
    navigate(`/video/${id}`);
    // Implement view in studio functionality
  };

  const purchaseCreditsForUser = () => {
    // Implement purchase credits functionality
  };

  let text1Color = colorMode === 'dark' ? 'text-neutral-100' : 'text-neutral-900';

  // Function to show the VideoGPT options dialog
  const showVideoGPTOptionsDialog = () => {
    const content = (
      <VideoGPTOptionsDialogContent
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        selectedAspectRatio={selectedAspectRatio}
        setSelectedAspectRatio={setSelectedAspectRatio}
        closeAlertDialog={closeAlertDialog}
      />
    );
    openAlertDialog(content);
  };

  // VideoGPTOptionsDialogContent Component
  const VideoGPTOptionsDialogContent = ({
    selectedDuration,
    setSelectedDuration,
    selectedAspectRatio,
    setSelectedAspectRatio,
    closeAlertDialog,
  }) => {
    const [duration, setDuration] = useState(selectedDuration);
    const [aspectRatio, setAspectRatio] = useState(selectedAspectRatio);

    const handleSubmit = () => {
      setSelectedDuration(duration);
      setSelectedAspectRatio(aspectRatio);
      closeAlertDialog();
    };

    return (
      <div className="p-4">
        <h2 className="text-xl font-bold mb-4">Video Options</h2>
        <div className="flex mb-4">
          {/* Duration Column */}
          <div className="w-1/2 pr-2">
            <h3 className="font-semibold mb-2">Duration</h3>
            <div className="mb-2">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="30"
                  checked={duration === '30'}
                  onChange={() => setDuration('30')}
                  className="form-radio"
                />
                <span className="ml-2">30 seconds</span>
              </label>
            </div>
            <div className="mb-2">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="60"
                  checked={duration === '60'}
                  onChange={() => setDuration('60')}
                  className="form-radio"
                />
                <span className="ml-2">60 seconds</span>
              </label>
            </div>
            <div className="mb-2">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="120"
                  checked={duration === '120'}
                  onChange={() => setDuration('120')}
                  className="form-radio"
                />
                <span className="ml-2">2 minutes</span>
              </label>
            </div>
          </div>
          {/* Aspect Ratio Column */}
          <div className="w-1/2 pl-2">
            <h3 className="font-semibold mb-2">Aspect Ratio</h3>
            <div className="mb-2">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="16:9"
                  checked={aspectRatio === '16:9'}
                  onChange={() => setAspectRatio('16:9')}
                  className="form-radio"
                />
                <span className="ml-2">16:9 (Landscape)</span>
              </label>
            </div>
            <div className="mb-2">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="9:16"
                  checked={aspectRatio === '9:16'}
                  onChange={() => setAspectRatio('9:16')}
                  className="form-radio"
                />
                <span className="ml-2">9:16 (Portrait)</span>
              </label>
            </div>
          </div>
        </div>
        <div className="text-right">
          <CommonButton onClick={handleSubmit}>Submit</CommonButton>
        </div>
      </div>
    );
  };

  let premiumUsersOnlyMessage = <span />;
  if (user && !user.isPremiumUser) {
    premiumUsersOnlyMessage = (
      <div className="text-xs mt-1 text-red-500">
        This feature is only available to premium users.
      </div>
    );
  }

  const [pricingDetailsDisplay, setPricingDetailsDisplay] = useState(false);
  const togglePricingDetailsDisplay = () => {
    setPricingDetailsDisplay(!pricingDetailsDisplay);
  }


  let pricingDetailsMessage = <span />;

  if (pricingDetailsDisplay) {
    pricingDetailsMessage = (
      <div className='block mt-1'>
        <div>The price is calculated as 100 credits per 10 seconds of video.</div>
        <div>For example, a 1 minute video will consume 600 credits.</div>
        {premiumUsersOnlyMessage}
      </div>
    )
  }

  return (
    <div className="mt-[100px] relative">
      <div className={`${text1Color} font-bold text-center mb-6 mt-4 h-[40px] block align-bottom text-center`}>
        <div
          className={`text-2xl block transform transition-transform duration-500 ease-out ${animateHeading ? '-translate-y-4' : ''
            }`}
        >
          VideoGPT By SamsarOne
        </div>
        {showSubheading && (
          <div className="text-sm block mt-[-10px]">
            <Typewriter text="One-Shot Text to Video" delay={50} />
            <FaCog className="inline-block ml-2 hover:text-neutral-200 cursor-pointer" onClick={showVideoGPTOptionsDialog} />
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <TextareaAutosize
          minRows={8}
          maxRows={20}
          className="w-full bg-gray-950 text-white pl-4 pt-4 p-2 rounded"
          placeholder="Enter a topic on which you'd like a story-video.
          For example: 'A 1 minute long journey through the cosmos' or 'A story in 6 lines on day in the life of a robot'."
          name="promptText"
          value={promptText}
          onChange={(e) => setPromptText(e.target.value)}
        />



        <div className="mt-4 relative">
          {/* Centered Submit Button */}
          <div className="flex justify-center">
            <CommonButton type="submit" isDisabled={isSubmitting || isDisabled}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </CommonButton>
          </div>
          {/* Pricing Preview positioned to the right on medium and larger screens */}


          <div className="md:absolute md:right-0 top-0 text-white p-2 bg-gray-900 rounded text-center mt-4 md:mt-0 w-full md:w-auto">
            <div className='relative'>
              {/* Updated Flex Container */}
              <div className="flex justify-end font-bold text-sm text-neutral-100 cursor-pointer" onClick={togglePricingDetailsDisplay} >
                Pricing will be shown at completion
                <FaChevronCircleDown 
                  className='inline-flex ml-2  mt-1' 
                  
                />
              </div>
              
              {/* Pricing Details Message */}
              <div className="mt-1 text-sm w-full text-right">
                {pricingDetailsMessage}
              </div>
            </div>
            </div>


        </div>

      </form>

      <div className="mt-4 text-neutral-100">
        <div className="flex items-center">
          Examples <FaChevronCircleDown className="ml-2" />
        </div>
      </div>

      {showResultDisplay && (
        <ProgressIndicator
          isGenerationPending={isGenerationPending}
          expressGenerationStatus={expressGenerationStatus}
          videoLink={videoLink}
          setShowResultDisplay={setShowResultDisplay}
          errorMessage={errorMessage}
          purchaseCreditsForUser={purchaseCreditsForUser}
          viewInStudio={viewInStudio}
        />
      )}
    </div>
  );
}