import React, { useState, useEffect } from "react";
import CommonButton from "../../common/CommonButton.tsx";
import { IMAGE_GENERAITON_MODEL_TYPES } from "../../../constants/Types.ts";
import { useColorMode } from "../../../contexts/ColorMode.js";
import TextareaAutosize from 'react-textarea-autosize';
import { IMAGE_MODEL_PRICES } from '../../../constants/ModelPrices.js';
import { RECRAFT_IMAGE_STYLES } from "../../../constants/Types.ts";

export default function PromptGenerator(props) {
  const {
    promptText,
    setPromptText,
    submitGenerateRequest,
    isGenerationPending,
    selectedGenerationModel,
    setSelectedGenerationModel,
    generationError,
    currentDefaultPrompt,
    submitGenerateNewRequest,
    aspectRatio,
  } = props;

  const { colorMode } = useColorMode();

  const [selectedImageStyle, setSelectedImageStyle] = useState(() => {
    if (selectedGenerationModel === 'RECRAFTV3' || selectedGenerationModel === 'RECRAFT20B') {
      const defaultRecraftModel = localStorage.getItem('defaultRecraftModel');
      if (defaultRecraftModel) {
        return defaultRecraftModel;
      } else {
        return RECRAFT_IMAGE_STYLES[0];
      }
    } else {
      return null;
    }
  });

  useEffect(() => {
    if (selectedGenerationModel === 'RECRAFTV3'  || selectedGenerationModel === 'RECRAFT20B') {
      const defaultRecraftModel = localStorage.getItem('defaultRecraftModel');
      if (defaultRecraftModel) {
        setSelectedImageStyle(defaultRecraftModel);
      } else {
        setSelectedImageStyle(RECRAFT_IMAGE_STYLES[0]);
      }
    } else {
      setSelectedImageStyle(null);
    }
  }, [selectedGenerationModel]);

  const selectBG = colorMode === "dark" ? "bg-gray-800" : "bg-gray-200";
  const textBG = colorMode === "dark" ? "bg-gray-800" : "bg-gray-200 border-gray-600 border-2";

  const modelOptionMap = IMAGE_GENERAITON_MODEL_TYPES.map((model) => (
    <option key={model.key} value={model.key}>
      {model.name}
    </option>
  ));

  const setSelectedModelDisplay = (evt) => {
    const newModel = evt.target.value;
    setSelectedGenerationModel(newModel);
    localStorage.setItem('defaultModel', newModel);
  };

  const handleImageStyleChange = (e) => {
    const newStyle = e.target.value;
    setSelectedImageStyle(newStyle);

    if (selectedGenerationModel === 'RECRAFTV3'  || selectedGenerationModel === 'RECRAFT20B') {
      localStorage.setItem('defaultRecraftModel', newStyle);
    }
  };

  const handleSubmit = () => {
    const payload = {
      prompt: promptText,
      model: selectedGenerationModel,
    };

    if (selectedGenerationModel === 'RECRAFTV3'  || selectedGenerationModel === 'RECRAFT20B') {
      payload.imageStyle = selectedImageStyle;
    }

    submitGenerateNewRequest(payload);
  };

  const modelPricing = IMAGE_MODEL_PRICES.find(model => model.key === selectedGenerationModel);
  const priceObj = modelPricing
    ? modelPricing.prices.find(price => price.aspectRatio === aspectRatio)
    : null;
  const modelPrice = priceObj ? priceObj.price : 0;

  const errorDisplay = generationError ? (
    <div className="text-red-500 text-center text-sm">
      {generationError}
    </div>
  ) : null;

  return (
    <div>
      <div className="flex w-full mt-2 mb-2">
        <div className="inline-flex w-[25%]">
          <div className="text-xs font-bold">
            Model
          </div>
        </div>
        <select
          onChange={setSelectedModelDisplay}
          className={`${selectBG} inline-flex w-[75%]`}
          value={selectedGenerationModel}
        >
          {modelOptionMap}
        </select>
      </div>

      {/* Image Style Selection Dropdown (only for RECRAFTV3) */}
      {(selectedGenerationModel === 'RECRAFTV3' || selectedGenerationModel === 'RECRAFT20B')&& (
        <div className="flex w-full mt-2 mb-2">
          <div className="inline-flex w-[25%]">
            <div className="text-xs font-bold">Image Style</div>
          </div>
          <select
            onChange={handleImageStyleChange}
            value={selectedImageStyle}
            className={`${selectBG} inline-flex w-[75%]`}
          >
            {RECRAFT_IMAGE_STYLES.map((style) => (
              <option key={style} value={style}>
                {style}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Display Expected Cost */}
      <div className="w-full mb-2">
        <div className="text-xs font-semibold">
          This action will incur <span className="text-blue-600">{modelPrice} Credits</span>
        </div>
      </div>

      <TextareaAutosize
        onChange={(evt) => setPromptText(evt.target.value)}
        placeholder="Add prompt text here"
        className={`${textBG} w-full m-auto p-4 rounded-lg`}
        minRows={3}
        value={promptText}
      />

      <div className="text-center">
        <CommonButton onClick={handleSubmit} isPending={isGenerationPending}>
          Submit
        </CommonButton>
      </div>
      {errorDisplay}
    </div>
  );
}
