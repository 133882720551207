import React from 'react';
import OverflowContainer from '../common/OverflowContainer.tsx';

import OneshotEditor from './OneshotEditor.js';

export default function OneshotEditorContainer() {
  console.log("HERERTGVVG");

  return (
    <div className='bg-gray-900'>
      <OverflowContainer>
        <div className='container m-auto'>
          <OneshotEditor />
        </div>
      </OverflowContainer>
    </div>
  )
}